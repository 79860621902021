export const formatDate = (timestamp) => {
    if (!timestamp) return "N/A"; // Handle missing timestamps
  
    let date;
    // Check if it's a Firestore Timestamp
    if (timestamp.toDate) {
      date = timestamp.toDate();
    } else if (timestamp instanceof Date) {
      // If it's already a Date object
      date = timestamp;
    } else if (typeof timestamp === "string") {
      // If it's a string, try parsing it
      date = new Date(timestamp);
    } else {
      console.error("Invalid timestamp format:", timestamp);
      return "Invalid Date";
    }
}