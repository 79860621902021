import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Paper,
  CircularProgress,
  Tabs,
  Tab,
  Card,
  CardContent,
  Box,
  Container,
  Snackbar,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { styled } from '@mui/material/styles';
import { firestore } from '../../firebase/firebaseConfig';
import moment from 'moment';
import StatusCard from '../../componants/StatusCard';

const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));


const NearMissReport = ({ userId, email, company_name }) => {
  const [dateOfNearMiss, setDateOfNearMiss] = useState(new Date());
  const [departmentName, setDepartmentName] = useState('');
  const [areaName, setAreaName] = useState('');
  const [reportingPersonName, setReportingPersonName] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [reportingPersonDesignation, setReportingPersonDesignation] = useState('');
  const [descriptionOfNearMiss, setDescriptionOfNearMiss] = useState('');

  const [departments, setDepartments] = useState([]);
  const [areas, setAreas] = useState([]);
  const [rpDesignation, setrpDesignation] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [caseCount, setCaseCount] = useState(0);

  const [tabValue, setTabValue] = useState(0);
  const [reports, setReports] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const departmentsSnapshot = await firestore
          .collection('company_data')
          .doc(company_name)
          .collection(company_name)
          .doc('for_admin')
          .collection('department_name')
          .get();
        const departmentData = departmentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setDepartments(departmentData);

        const areasSnapshot = await firestore
          .collection('company_data')
          .doc(company_name)
          .collection(company_name)
          .doc('for_admin')
          .collection('company_zones')
          .get();
        const areaData = areasSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setAreas(areaData);

        const designationSnapshot = await firestore
          .collection('company_data')
          .doc(company_name)
          .collection(company_name)
          .doc('for_admin')
          .collection('reporting_person_designation')
          .get();
        const rpdData = designationSnapshot.docs.map((doc) => ({ id: doc.id, name: doc.data().name }));
        setrpDesignation(rpdData);

        const companyDoc = await firestore.collection('company_data').doc(company_name).get();
        if (companyDoc.exists) {
          setCaseCount(companyDoc.data().case_count || 0);
        }

        fetchReports();
      } catch (error) {
        console.error('Error fetching data:', error);
        setSnackbarMessage('Error fetching data. Please try again.');
        setSnackbarOpen(true);
      }
    };

    fetchData();
  }, [company_name, userId]);

  const fetchReports = async () => {
    try {
      const reportsSnapshot = await firestore
        .collection('company_data')
        .doc(company_name)
        .collection(company_name)
        .doc('for_user')
        .collection('near_miss_report')
        .where('userId', '==', userId)
        .get();

      const reportsData = reportsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setReports(reportsData);
    } catch (error) {
      console.error('Error fetching reports:', error);
      setSnackbarMessage('Error fetching reports. Please try again.');
      setSnackbarOpen(true);
    }
  };

  const validate = () => {
    const errors = {};
    if (!dateOfNearMiss) errors.dateOfNearMiss = 'Date of Near Miss is required';
    if (!departmentName) errors.departmentName = 'Department Name is required';
    if (!areaName) errors.areaName = 'Area Name is required';
    if (!reportingPersonName) errors.reportingPersonName = 'Reporting Person Name is required';
    if (!employeeId) errors.employeeId = 'Employee Id is required';
    if (!reportingPersonDesignation) errors.reportingPersonDesignation = 'Reporting Person Designation is required';
    if (!descriptionOfNearMiss) errors.descriptionOfNearMiss = 'Description of Near Miss is required';
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setIsLoading(true);
      try {
        const caseNumber = caseCount + 1;
        await firestore
          .collection('company_data')
          .doc(company_name)
          .collection(company_name)
          .doc('for_user')
          .collection('near_miss_report')
          .add({
            dateOfNearMiss,
            departmentName,
            areaName,
            reportingPersonName,
            employeeId,
            reportingPersonDesignation,
            descriptionOfNearMiss,
            caseNumber,
            userId,
            isPending: true,
          });

        await firestore.collection('company_data').doc(company_name).update({
          case_count: caseNumber,
        });

        clearForm();
        fetchReports();
        setSnackbarMessage('Form submitted successfully');
        setSnackbarOpen(true);
      } catch (error) {
        console.error('Error submitting form:', error);
        setSnackbarMessage('Failed to submit form. Please try again.');
        setSnackbarOpen(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const clearForm = () => {
    setDateOfNearMiss(new Date());
    setDepartmentName('');
    setAreaName('');
    setReportingPersonName('');
    setEmployeeId('');
    setReportingPersonDesignation('');
    setDescriptionOfNearMiss('');
    setErrors({});
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const formatDate = (timestamp) => {
    if (typeof timestamp === 'string' && timestamp.includes('-')) {
      return timestamp;
    }
    if (timestamp && timestamp.seconds) {
      return moment(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).format('MMMM D, YYYY');
    }
    return 'Invalid Date';
  };

  return (
    <Container maxWidth="lg" sx={{mt:4}}>
        <FormControl onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date of Near Miss"
                  value={dateOfNearMiss}
                  onChange={setDateOfNearMiss}
                  renderInput={(params) => (
                    <TextField 
                      {...params} 
                      fullWidth 
                      error={!!errors.dateOfNearMiss}
                      helperText={errors.dateOfNearMiss}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!!errors.departmentName}>
                <InputLabel>Department Name</InputLabel>
                <Select
                  value={departmentName}
                  onChange={(e) => setDepartmentName(e.target.value)}
                >
                  {departments.map((dept) => (
                    <MenuItem key={dept.id} value={dept.name}>
                      {dept.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.departmentName && (
                  <Typography variant="caption" color="error">
                    {errors.departmentName}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!!errors.areaName}>
                <InputLabel>Area Name</InputLabel>
                <Select
                  value={areaName}
                  onChange={(e) => setAreaName(e.target.value)}
                >
                  {areas.map((area) => (
                    <MenuItem key={area.id} value={area.name}>{area.name}</MenuItem>
                  ))}
                </Select>
                {errors.areaName && <Typography color="error">{errors.areaName}</Typography>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Reporting Person Name"
                value={reportingPersonName}
                onChange={(e) => setReportingPersonName(e.target.value)}
                fullWidth
                error={!!errors.reportingPersonName}
                helperText={errors.reportingPersonName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Employee Id"
                value={employeeId}
                onChange={(e) => setEmployeeId(e.target.value)}
                fullWidth
                error={!!errors.employeeId}
                helperText={errors.employeeId}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!!errors.reportingPersonDesignation}>
                <InputLabel>Reporting Person Designation</InputLabel>
                <Select
                  value={reportingPersonDesignation}
                  onChange={(e) => setReportingPersonDesignation(e.target.value)}
                >
                  {rpDesignation.map((rpd) => (
                    <MenuItem key={rpd.id} value={rpd.name}>{rpd.name}</MenuItem>
                  ))}
                </Select>
                {errors.reportingPersonDesignation && <Typography color="error">{errors.reportingPersonDesignation}</Typography>}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description of Near Miss"
                value={descriptionOfNearMiss}
                onChange={(e) => setDescriptionOfNearMiss(e.target.value)}
                fullWidth
                multiline
                rows={4}
                error={!!errors.descriptionOfNearMiss}
                helperText={errors.descriptionOfNearMiss}
              />
            </Grid>
          </Grid>
          <Box mt={3}>
            <StyledButton
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Submit'}
            </StyledButton>
            <Button variant="outlined" onClick={clearForm}>
              Clear
            </Button>
          </Box>
        </FormControl>

      <Container maxWidth="lg" sx={{mt:4}}>
        <Typography variant="h5" gutterBottom>
          Your Reports
        </Typography>
        <Tabs 
         value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
        >
          <Tab label="Pending" />
          <Tab label="Completed" />
        </Tabs>
        <Box mt={3} sx={{paddingBottom:4}}>
          <Grid container spacing={3}>
          {reports
              .filter(report => tabValue === 0 ? report.isPending : !report.isPending)
              .map(report => (
             
                <StatusCard 
                key={report.id}
                status={report.isPending ?'Pending' : 'Completed'}
                severity={report.severity}
                caseNumber={report.caseNumber}
                date={formatDate(report.date)}
                areaName={report.areaName}
                description={report.description}
                />
          
              ))}
          </Grid>
        </Box>
      </Container>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Container>
  );
};

export default NearMissReport;