import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "firebase/compat/storage";
import { doc, updateDoc } from "firebase/firestore";
import firebase from "firebase/compat/app";
import { Box, Button, CircularProgress, Container, Divider, Grid, IconButton, Modal, Paper, styled, Tab, Tabs, TextField, Typography, useMediaQuery } from "@mui/material";
import DataTable from "../../componants/common/DataTable";
import { CheckCircle, Warning } from "@mui/icons-material";
import { firestore } from "../../firebase/firebaseConfig";
import StatusCard from "../../componants/StatusCard";
import { Close } from "@material-ui/icons";
import { formatDate } from "../../functions/FormateDate";
import moment from "moment";

const ModalContent = styled(Paper)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 600,
  maxHeight: "90vh",
  overflowY: "auto",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  outline: "none",
}));


const UnsafeActList = () => {

  const location = useLocation();
  const { company_name } = location.state || {};

  // ... (state and other functions remain the same)
  const [data, setData] = useState([]);
  const [date, setDate] = useState(new Date());
  const [tabValue, setTabValue] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUnsafeAct, setSelectedUnsafeAct] = useState(null);
  const [imageOfVerification, setImageOfverification] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Use Material-UI's media query hook to adjust layout for different devices
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await firestore
          .collection("company_data")
          .doc(company_name)
          .collection(company_name)
          .doc("for_user")
          .collection("unsafe_act")
          .orderBy("createdAt", "desc")
          .get();

        const records = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        
        setData(records);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [company_name]);

  //Filtering report for pending and completed tabs
  const filteredunsafeActs = data.filter((act) => (tabValue === 0 ? act.isPending : !act.isPending))
  
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleModalOpen = (unsafeAct) => {
    setSelectedUnsafeAct(unsafeAct);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedUnsafeAct(null);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
    
  };

  const handleImageChange = (e) => {
    if (e.target.files.length > 0) {
      setImageOfverification(e.target.files[0]);
    }
  };

  // Functiona for Closing Opened Unsafe Act & Condition
  const handleCloseUnsafeAct = async () => {
    setIsLoading(true);
    let imageUrl = null;
    if (imageOfVerification) {
      imageUrl = await uploadImage(imageOfVerification);
    }
    try {
      const unsafeActRef = doc(
        firestore,
        "company_data",
        company_name,
        company_name,
        "for_user",
        "unsafe_act",
        selectedUnsafeAct.id
      );
      await updateDoc(unsafeActRef, {
        isPending: false,
        dateOfClosing: date,
        imageOfVerification: imageUrl,
      });
      setIsLoading(false);
      handleModalClose();
    } catch (error) {
      console.error("Error updating unsafe act:", error);
      setIsLoading(false);
    }
  };

  // Upload image in firebase
  const uploadImage = async (file) => {
    if (!file) return null;

    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(
      `unsafe_act_report_images/${Date.now()}_${file.name}`
    );

    try {
      await fileRef.put(file);
      const downloadURL = await fileRef.getDownloadURL();
      return downloadURL;
    } catch (error) {
      console.error("Error uploading image: ", error);
      throw error;
    }
  };

  return(
    <Container maxWidth="lg" sx={{mt:4}}>
    <DataTable company_name={company_name}/>
    <Container maxWidth>
        <Tabs value={tabValue} onChange={handleTabChange} variant="fullWidth">
          <Tab label="Pending" icon={<Warning />} iconPosition="start" />
          <Tab
            label="Completed"
            icon={<CheckCircle />}
            iconPosition="start"
          />
        </Tabs>
        <Box mt={3} sx={{ paddingBottom: 4, pl:4 }}>
          <Grid container spacing={3}>
            {filteredunsafeActs.map((unsafeAct) => (
                <StatusCard
                  key={unsafeAct.id}
                  status={unsafeAct.isPending ? "Pending" : "Completed"}
                  severity={unsafeAct.severity}
                  caseNumber={unsafeAct.caseNumber}
                  date={formatDate(unsafeAct.date)}
                  areaName={unsafeAct.areaName}
                  description={unsafeAct.description}
                  onViewDetails={() => handleModalOpen(unsafeAct)}

                  // Optional: Add custom rendering for countdown if StatusCard supports it
                  additionalDetails={
                    unsafeAct.remainingTime && unsafeAct.isPending
                      ? `${
                          unsafeAct.remainingTime.isOverdue
                            ? "Overdue"
                            : `${unsafeAct.remainingTime.days} days ${unsafeAct.remainingTime.hours} hours remaining`
                        }`
                      : null
                  }
                />
            ))}
          </Grid>
        </Box>
    </Container>

     {/* Details Modal */}
     <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="unsafe-act-modal-title"
      >
        <ModalContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography id="unsafe-act-modal-title" variant="h5" component="h2">
              Unsafe Act Details
            </Typography>
            <IconButton onClick={handleModalClose} size="large">
              <Close />
            </IconButton>
          </Box>
          {selectedUnsafeAct && (
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Case Number</Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUnsafeAct.caseNumber}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Date of Reporting</Typography>
                  <Typography variant="body1" gutterBottom>
                    {formatDate(selectedUnsafeAct.date)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Description</Typography>
                  <Typography variant="body1" paragraph>
                    {selectedUnsafeAct.description}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Area Name</Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUnsafeAct.areaName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Department Name</Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUnsafeAct.departmentName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Auditor Name</Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUnsafeAct.auditorName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Employee ID</Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUnsafeAct.employeeId}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    Reporting Person Designation
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUnsafeAct.reportingPersonDesignation}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    Responsible Department
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUnsafeAct.responsibleDepartment}
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ my: 3 }} />
              {selectedUnsafeAct.image && (
                <Box mt={2} mb={3}>
                  <Typography variant="subtitle1" gutterBottom>
                    Reported Image
                  </Typography>
                  <img
                    src={selectedUnsafeAct.image}
                    alt="Unsafe Act"
                    style={{ width: "100%", borderRadius: "4px" }}
                  />
                </Box>
              )}
              {selectedUnsafeAct.isPending && (
                <Box mt={3}>
                  <Typography variant="h6" gutterBottom>
                    Close Unsafe Act
                  </Typography>
                  <TextField
                    label="Date of Closing"
                    type="date"
                    value={moment(date).format("YYYY-MM-DD")}
                    onChange={handleDateChange}
                    fullWidth
                    margin="normal"
                    disabled={true}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Box mt={2}>
                    <input
                      accept="image/*"
                      id="contained-button-file"
                      type="file"
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="contained-button-file">
                      <Button variant="outlined" component="span" fullWidth>
                        Upload Verification Image
                      </Button>
                    </label>
                    {imageOfVerification && (
                      <Typography variant="body2" color="textSecondary" mt={1}>
                        {imageOfVerification.name}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    mt={3}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCloseUnsafeAct}
                      disabled={isLoading}
                    >
                      {isLoading ? "Closing..." : "Close Unsafe Act"}
                    </Button>
                    {isLoading && <CircularProgress size={24} />}
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default UnsafeActList;
