import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import {  useMediaQuery } from "@mui/material";

import UserDrawer from "./UserDrawer";

// Import your user components here
import Dashboard from "./users/Dashboard";
import NearMissReport from "./users/NearMissReport";
import UnsafeActReport from "./users/UnsafeActReport";
import AccidentReport from "./users/AccidentReport";
import WeeklySafetyMeeting from "./users/WeeklySafetyMeeting";
import FireInjuryReport from "./users/FireInjuryReport";
import PPEGrievancesReport from "./users/PPEGrievancesReport";
import KaizenReport from "./users/KaizenReport";
import WorkPermit from "./users/WorkPermit";

// Import chart components
import NearMissChart from "./charts/NearMissChart";
import UnsafeActChart from "./charts/UnsafeActChart";
import AccidentReportChart from "./charts/AccidentReportChart";
import WeeklySafetyMeetingChart from "./charts/WeeklySafetyMeetingChart";
import FireInjuryChart from "./charts/FireInjuryChart";
import PPEGrievancesChart from "./charts/PPEGrievancesChart";
import KaizenReportChart from "./charts/KaizenReportChart";
import WorkPermitChart from "./charts/WorkPermitChart";
import { Box } from "@mui/material";

//Added manager companent

import FireInjuryList from "./managers/FireInjuryList";
import NearMissReportList from "./managers/NearMissReportList";
import PPEGrievancesList from "./managers/PPEGrievancesList";
import UnsafeActList from "./managers/UnsafeActList";
import WorkPermitList from "./managers/WorkPermitList";
import WorkPermitAuditorList from "./managers/WorkPermitAuditorList";
import KaizenReportsList from "./managers/KaizenReportsList";
import AccidentReportList from "./managers/AccidentReportList";
import AuditForm from "./users/Audit/AuditForm";
import SafetyAuditQuestionnaire from "./users/Audit/Audit";
import AuditReportList from "./users/Audit/AuditReportList";
import ExameForm from "./users/Exame/ExameForm";
import UserProfile from "../componants/common/UserProfile";


//audit_homepage (email,company_name,id)



const UserRouter = () => {

  const exameLink = 'https://docs.google.com/forms/d/e/1FAIpQLSdArj_O03lFFTeE7Ui9LvdtCEt5eZ9o7fmlx58WMYKdpgSVQw/viewform?embedded=true';

 // Use Material-UI's media query hook to adjust layout for different devices
 const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const storedData = localStorage.getItem("userData");

    if (location.state) {
      localStorage.setItem("userData", JSON.stringify(location.state));
      setUserData(location.state);
    } else if (storedData) {
      const parsedData = JSON.parse(storedData);
      setUserData(parsedData);
      navigate(location.pathname, { state: parsedData, replace: true });
    } else {
      navigate("/Login/User");
    }

    setLoading(false); // Stop the loading animation once data is resolved
  }, [location, navigate]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <div>Loading user data...</div>
        <div className="loading-bar"></div>
      </Box>
    );
  }

  if (!userData?.permissions) {
    console.error("Permissions are undefined in UserRouter");
    navigate("/Login/User"); // Redirect to login if permissions are missing
    return (
      <div>
        Error: Unable to load user data. Please try logging in again.
      </div>
    );
  }

  const { userId, email, company_name, permissions } = userData;

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
    }}>

    {/* Drawer area, can be collapsed for smaller screens */}
      <Box
        sx={{
          flexShrink: 0,
          width: isSmallScreen ? "100%" : "240px", 
          marginBottom: isSmallScreen ? "" : "40px",       
          position: isSmallScreen ? "relative" : "fixed",
          zIndex: 1200,
          borderRight: isSmallScreen ? "none" : "solid 1px #ddd",
        }}
      >
        <UserDrawer permissions={permissions} />
      </Box>

        {/* Main content area */}
      <Box
        sx={{
          flex: 1,
          marginLeft: isSmallScreen ? 1 : "250px",
          marginTop: isSmallScreen? 0 : 9,
          height: "100%",
          overflow: "auto",
        }}
      >
        <Routes >
          <Route path="/dashboard" element={<Dashboard userId={userId} company_name={company_name} permissions={permissions} /> } />
          <Route path="/audit" element={<AuditForm userId={userId}/>} />
          <Route path="/safety-questionnaire" element={<SafetyAuditQuestionnaire company_name={company_name}/>} />
          <Route path="/audit-report" element={<AuditReportList userId={userId} companyName={company_name}/>} />
          <Route path="/exames" element={exameLink && <ExameForm exameLink={exameLink}/>} />
          <Route path="/profile" element={<UserProfile user={userData} />} />
          {permissions.includes("Near Miss Report") && (
            <>
              <Route
                path="near-miss-report"
                element={
                  <NearMissReport
                    userId={userId}
                    email={email}
                    company_name={company_name}
                  />
                }
              />
              <Route
                path="near-miss-report-chart"
                element={
                  <NearMissChart userId={userId} company_name={company_name} />
                }
              />
            </>
          )}
          {permissions.includes("Unsafe Act") && (
            <>
              <Route
                path="unsafe-act-report"
                element={
                  <UnsafeActReport
                    userId={userId}
                    email={email}
                    company_name={company_name}
    
                  />
                }
              />
              <Route
                path="unsafe-act-report-chart"
                element={
                  <UnsafeActChart userId={userId} company_name={company_name} />
                }
              />
            </>
          )}
          {permissions.includes("Accident Report") && (
            <>
              <Route
                path="accident-report"
                element={
                  <AccidentReport
                    userId={userId}
                    email={email}
                    company_name={company_name}
                  />
                }
              />
              <Route
                path="accident-report-chart"
                element={
                  <AccidentReportChart
                    userId={userId}
                    company_name={company_name}
                  />
                }
              />
            </>
          )}
          {permissions.includes("Weekly Safety Meeting") && (
            <>
              <Route
                path="weekly-safety-meeting"
                element={
                  <WeeklySafetyMeeting
                    userId={userId}
                    email={email}
                    company_name={company_name}
                  />
                }
              />
              <Route
                path="weekly-safety-meeting-chart"
                element={
                  <WeeklySafetyMeetingChart
                    userId={userId}
                    company_name={company_name}
                  />
                }
              />
            </>
          )}
          {permissions.includes("Fire Injury") && (
            <>
              <Route
                path="fire-injury-report"
                element={
                  <FireInjuryReport
                    userId={userId}
                    email={email}
                    company_name={company_name}
                  />
                }
              />
              <Route
                path="fire-injury-report-chart"
                element={
                  <FireInjuryChart
                    userId={userId}
                    company_name={company_name}
                  />
                }
              />
            </>
          )}
          {permissions.includes("PPE Grievances") && (
            <>
              <Route
                path="ppe-grievances-report"
                element={
                  <PPEGrievancesReport
                    userId={userId}
                    email={email}
                    company_name={company_name}
                  />
                }
              />
              <Route
                path="ppe-grievances-report-chart"
                element={
                  <PPEGrievancesChart
                    userId={userId}
                    company_name={company_name}
                  />
                }
              />
            </>
          )}
          {permissions.includes("Kaizen Reports") && (
            <>
              <Route
                path="kaizen-report"
                element={
                  <KaizenReport
                    userId={userId}
                    email={email}
                    company_name={company_name}
                  />
                }
              />
              <Route
                path="kaizen-report-chart"
                element={
                  <KaizenReportChart
                    userId={userId}
                    company_name={company_name}
                  />
                }
              />
            </>
          )}
          {permissions.includes("Work Permit") && (
            <>
              <Route
                path="work-permit"
                element={
                  <WorkPermit
                    userId={userId}
                    email={email}
                    company_name={company_name}
                  />
                }
              />
              <Route
                path="work-permit-chart"
                element={
                  <WorkPermitChart
                    userId={userId}
                    company_name={company_name}
                  />
                }
              />
            </>
          )}
          {/* added for managers */}
          {userData.role === "manager" && (
            <>
              <Route
                path="/"
                element={
                  <Dashboard
                    userId={userId}
                    company_name={company_name}
                    permissions={permissions}
                  />
                }
              />
              <Route
                path="fire-injury-list"
                element={
                  <FireInjuryList userId={userId} company_name={company_name} />
                }
              />
              <Route
                path="near-miss-list"
                element={
                  <NearMissReportList
                    userId={userId}
                    company_name={company_name}
                  />
                }
              />
              <Route
                path="ppe-grievances-list"
                element={
                  <PPEGrievancesList
                    userId={userId}
                    company_name={company_name}
                  />
                }
              />
              <Route
                path="unsafe-act-list"
                element={
                  <UnsafeActList userId={userId} company_name={company_name} />
                }
              />
              <Route
                path="work-permit-list"
                element={
                  <WorkPermitList userId={userId} company_name={company_name} />
                }
              />
              <Route
                path="work-permit-auditor-list"
                element={
                  <WorkPermitAuditorList
                    userId={userId}
                    company_name={company_name}
                  />
                }
              />
              <Route
                path="kaizen-reports-list"
                element={
                  <KaizenReportsList
                    userId={userId}
                    company_name={company_name}
                  />
                }
              />
              <Route
                path="accident-report-list"
                element={
                  <AccidentReportList
                    userId={userId}
                    company_name={company_name}
                  />
                }
              />
            </>
          )}
        </Routes>
      </Box>
    </Box>
  );
};
export default UserRouter;