import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  TablePagination,
  Collapse,
  Typography,
} from "@mui/material";
import { firestore } from "../../firebase/firebaseConfig";
import { collection, getDocs } from "firebase/firestore";

const DataTable = ({ company_name }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  // Fetch data from Firebase
  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await firestore
          .collection("company_data")
          .doc(company_name)
          .collection(company_name)
          .doc("for_user")
          .collection("unsafe_act")
          .orderBy("createdAt", "desc")
          .limit(rowsPerPage)
          .get();

        const records = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const countSnapshot = await firestore
          .collection("company_data")
          .doc(company_name)
          .collection(company_name)
          .doc("for_user")
          .collection("unsafe_act")
          .get();

        setTotalRecords(countSnapshot.size);
        setData(records);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [company_name, page, rowsPerPage]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return "N/A"; // Handle missing timestamps
    const date = timestamp.toDate(); // Convert to JavaScript Date
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  return (
    <Box sx={{ width: "100%", mb: 2 }}>
      <Typography variant="h6" component="div" sx={{mb:2}}>
        Reported Cases
      </Typography>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Case Number</TableCell>
                <TableCell>Auditor Name</TableCell>
                <TableCell>Type of observation</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Severity</TableCell>
                <TableCell>Area</TableCell>
                <TableCell>Department</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.caseNumber}</TableCell>
                  <TableCell>{row.auditorName}</TableCell>
                  <TableCell>{row.typeOfObservation}</TableCell>
                  <TableCell>{row.date?.toDate().toLocaleDateString() || 'N/A'}</TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        backgroundColor:
                          row?.severity === "High"
                            ? "#ffebee"
                            : row?.severity === "Medium"
                            ? "#fff3e0"
                            : "#e8f5e9",
                        color:
                          row?.severity === "High"
                            ? "#c62828"
                            : row?.severity === "Medium"
                            ? "#ef6c00"
                            : "#2e7d32",
                        padding: "4px 8px",
                        borderRadius: "4px",
                        display: "inline-block",
                      }}
                    >
                      {row?.severity || "Unknown"}
                    </Box>
                  </TableCell>

                  <TableCell>{row.areaName}</TableCell>
                  <TableCell>{row.departmentName}</TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        backgroundColor: row.isPending ? "#fff3e0" : "#e8f5e9",
                        color: row.isPending ? "#ef6c00" : "#2e7d32",
                        padding: "4px 8px",
                        borderRadius: "4px",
                        display: "inline-block",
                      }}
                    >
                      {row.isPending ? "Pending" : "Closed"}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </Paper>
    </Box>
  );
};

export default DataTable;
