import React from "react";
import { Grid, Card, CardContent, Box, Typography, Stack, useMediaQuery } from "@mui/material";
import { Info, SwapHoriz } from "@mui/icons-material";

const CustomCard = ({ bannerText, onOpenClick, onCloseClick, openTitle, openValue, closedTitle, closedValue }) => {

  // Use Material-UI's media query hook to adjust layout for different devices
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card
        sx={{
          width:"100%",
          display: "flex",
          flexDirection:"column",
          justifyContent: "space-evenly",
          position: "relative",
          transition: "transform 0.3s ease-in-out",
          "&:hover": {
            transform: "scale(1.05)",
          },
          backgroundColor: "black",
          color: "white",
        }}
      >
        {/* Banner Section */}
        <Box
          sx={{
            top: 0,
            left: 0,
            padding: "4px 12px",
            position: "absolute",
            borderBottomRightRadius: "6px",
            fontWeight: "bold",
            fontSize: { xs: "0.6rem", sm: "1rem" },
            backgroundColor: "orange",
          }}
        >
          {bannerText}
        </Box>

        {/* Content Section */}
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: { xs: "2px", sm: "4px" },
            mt:{xs:"24px", sm: "21px",lg:"28px"}
          }}
        >
          <Stack
            direction="row"
            sx={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              gap: { xs: 0, sm: 0 },
            }}
          >
            {/* Open Section */}
            <Stack spacing={1} alignItems="center">
              <Typography
                variant="h3"
                sx={{ fontSize: { xs: "1rem", sm: "3rem" }, color: "white" }}
              >
                {openValue || 0}
              </Typography>
              <Box
                sx={{
                  width: 100,
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  color="orange"
                  sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
                >
                  {openTitle}
                </Typography>
                <Info onClick={onOpenClick} sx={{ cursor: "pointer", fontSize:{xs:"1rem" ,sm:"2rem"} }} />
              </Box>
            </Stack>

            {/* Icon Separator */}
            <SwapHoriz
              fontSize="large"
              sx={{
                fontSize: { xs: "1rem", sm: "2rem" },
                margin: { xs: "4px 0", sm: "0 16px" },
              }}
            />

            {/* Closed Section */}
            <Stack spacing={1} alignItems="center">
              <Typography
                variant="h3"
                sx={{ fontSize: { xs: "1rem", sm: "3rem" }, color: "white" }}
              >
                {closedValue || 0}
              </Typography>
              <Box
                sx={{
                  width: 100,
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  color="green"
                  sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
                >
                  {closedTitle}
                </Typography>
                <Info onClick={onCloseClick} sx={{ cursor: "pointer",fontSize:{xs:"1rem" ,sm:"2rem"} }} />
              </Box>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CustomCard;
