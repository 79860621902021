import React, { useState, useEffect } from 'react';
import { doc, updateDoc } from "firebase/firestore";
import { Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Paper, CircularProgress, FormControlLabel, Checkbox, Tabs, Tab, Box, Container, Snackbar, Modal, IconButton, Divider, useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { firestore } from '../../firebase/firebaseConfig';
import firebase from 'firebase/compat/app';
import moment from 'moment';
import StatusCard from '../../componants/StatusCard';
import { Close } from '@mui/icons-material';


const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  //marginLeft: theme.spacing(0),
}));

// const StyledButton = styled(Button)(({ theme }) => ({
//   marginRight: theme.spacing(2),
// }));

const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

const ModalContent = styled(Paper)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 600,
  maxHeight: "90vh",
  overflowY: "auto",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  outline: "none",
}));

const UnsafeActReport = ({ userId, email, company_name }) => {

 const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
 const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [formData, setFormData] = useState({
    date: new Date(),
    auditorName: '',
    employeeId: '',
    reportingPersonDesignation: '',
    typeOfObservation: '',
    description: '',
    image: null,
    severity: '',
    immediateActionRequired: false,
    departmentName: '',
    areaName: '',
    responsiblePerson: '',
    responsibleDepartment: '',
    plannedDateOfClosing: new Date(),
  });

  const [departments, setDepartments] = useState([]);
  const [areas, setAreas] = useState([]);
  const [rpDesignation, setrpDesignation] = useState([]);
  const [rDept, setRDept] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [caseCount, setCaseCount] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [reports, setReports] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUnsafeAct, setSelectedUnsafeAct] = useState(null);
  const [date, setDate] = useState(new Date());
  const [imageOfVerification, setImageOfverification] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

    // Use Material-UI's media query hook to adjust layout for different devices
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchData = async () => {
      const companyRef = firestore.collection('company_data').doc(company_name);
      
      // Fetch departments
      const departmentsSnapshot = await companyRef
        .collection(company_name)
        .doc('for_admin')
        .collection('department_name')
        .get();
      setDepartments(departmentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

      // Fetch areas
      const areasSnapshot = await companyRef
        .collection(company_name)
        .doc('for_admin')
        .collection('company_zones')
        .get();
      setAreas(areasSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

      // Fetch reporting person designations
      const designationSnapshot = await companyRef
        .collection(company_name)
        .doc('for_admin')
        .collection('reporting_person_designation')
        .get();
      setrpDesignation(designationSnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name })));

      // Fetch responsible departments
      const deptSnapshot = await companyRef
        .collection(company_name)
        .doc('for_admin')
        .collection('responsible_department')
        .get();
      setRDept(deptSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

      // Fetch case count
      const companyDoc = await companyRef.get();
      if (companyDoc.exists) {
        setCaseCount(companyDoc.data().case_count || 0);
      }

      // Fetch user reports
      fetchReports();
    };

    fetchData();
  }, [ company_name ]);

  const fetchReports = async () => {
    const reportsSnapshot = await firestore
      .collection('company_data')
      .doc(company_name)
      .collection(company_name)
      .doc('for_user')
      .collection('unsafe_act')
      .where('userId', '==', userId)
      .get();

    setReports(reportsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const handleModalOpen = (unsafeAct) => {
    setSelectedUnsafeAct(unsafeAct);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedUnsafeAct(null);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleDateChange = (name, date) => {
    setFormData(prevData => ({ ...prevData, [name]: date }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData(prevData => ({ ...prevData, [name]: checked }));
  };

  const handleImageChange = (event) => {
    if (event.target.files.length > 0) {
      setFormData(prevData => ({ ...prevData, image: event.target.files[0] }));
    }
  };

  const validate = () => {
    const newErrors = {};
    Object.keys(formData).forEach(key => {
      if (!formData[key] && key !== 'image' && key !== 'immediateActionRequired') {
        newErrors[key] = 'This field is required';
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const uploadImage = async (file) => {
    if (!file) return null;
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(`images/${company_name}/${new Date().toISOString()}`);
    try {
      await fileRef.put(file);
      return await fileRef.getDownloadURL();
    } catch (error) {
      console.error('Error uploading image: ', error);
      throw error;
    }
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validate()) {
      setIsLoading(true);
      try {
        const caseNumber = caseCount + 1;
        let imageUrl = null;
        if (formData.image) {
          imageUrl = await uploadImage(formData.image);
        }
   
        const docRef = await firestore
          .collection('company_data')
          .doc(company_name)
          .collection(company_name)
          .doc('for_user')
          .collection('unsafe_act')
          .add({
            ...formData,
            image: imageUrl,
            caseNumber,
            userId,
            isPending: true,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(), // Added for sorting and notification
          });
  
          // Update case count
        await firestore.collection('company_data').doc(company_name)
          .update({
            case_count: caseNumber,
          });

                // Add notification
      await firestore
      .collection('company_data')
      .doc(company_name)
      .collection('notifications')
      .add({
        title:`New ${formData.typeOfObservation} reported by ${formData.auditorName}.`,
        message: `${formData.description}.`,
        caseNumber,
        severity:`${formData.severity}`,
        company: company_name,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        isRead: false,
      });
     
  
        setFormData({
          date: new Date(),
          auditorName: '',
          employeeId: '',
          reportingPersonDesignation: '',
          typeOfObservation: '',
          description: '',
          image: null,
          severity: '',
          immediateActionRequired: false,
          departmentName: '',
          areaName: '',
          responsiblePerson: '',
          responsibleDepartment: '',
          plannedDateOfClosing: new Date(),
        });
        setErrors({});
        setIsLoading(false);
        setSnackbarMessage('Form submitted successfully');
        setSnackbarOpen(true);
        fetchReports();
      } catch (error) {
        console.error('Error submitting form:', error);
        console.error('Error creating notifications:', error);
        setIsLoading(false);
        setSnackbarMessage('Failed to submit form');
        setSnackbarOpen(true);
      }
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const formatDate = (timestamp) => {
    if (typeof timestamp === 'string' && timestamp.includes('-')) {
      return timestamp;
    }
    if (timestamp && timestamp.seconds) {
      return moment(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).format('MMMM D, YYYY');
    }
    return 'Invalid Date';
  };

    // Functiona for Closing Opened Unsafe Act & Condition
    const handleCloseUnsafeAct = async () => {
      setIsLoading(true);
      let imageUrl = null;
      if (imageOfVerification) {
        imageUrl = await uploadImage(imageOfVerification);
      }
      try {
        const unsafeActRef = doc(
          firestore,
          "company_data",
          company_name,
          company_name,
          "for_user",
          "unsafe_act",
          selectedUnsafeAct.id
        );
        await updateDoc(unsafeActRef, {
          isPending: false,
          dateOfClosing: date,
          imageOfVerification: imageUrl,
        });
        setIsLoading(false);
        handleModalClose();
      } catch (error) {
        console.error("Error updating unsafe act:", error);
        setIsLoading(false);
      }
    };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container
       maxWidth="lg"
        sx={{
          mt:isSmall ? 8 : 12,
         // border: '2px solid black',
        }}
        // sx={{
        //   mt: 12,
        //   px: isSmall ? 1 : 3,
        //   '& .MuiTextField-root': {
        //     fontSize: isSmall ? '0.8rem' : '1rem',
        //   },
        //   '& .MuiButton-root': {
        //     fontSize: isSmall ? '0.8rem' : '1rem',
        //   }
        // }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={isSmall ? 2 : 3}>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Date"
                  value={formatDate(Date)}
                  onChange={(date) => handleDateChange('date', date)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />

                {/* {isSmall ? (
                  <MobileDatePicker
      label="Date"
      value={formData.date}
      onChange={(date) => handleDateChange('date', date)}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          sx={{
            '& .MuiInputBase-input': {
              fontSize: '0.75rem', // Adjust font size for mobile
            },
            '& .MuiSvgIcon-root': {
              fontSize: '1rem', // Adjust icon size for mobile
            },
          }}
        />
      )}
      DialogProps={{
        PaperProps: {
          sx: {
            width: '90%', // Adjust width of the modal
            maxWidth: '300px', // Set a maximum width
            height: 'auto', // Adjust height of the modal
            maxHeight: '400px', // Set a maximum height
          },
        },
      }}
    />
                  ) : (
                    <DatePicker
                      label="Date"
                      value={formData.date}
                      onChange={(date) => handleDateChange('date', date)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          sx={{
                            '& .MuiInputBase-input': {
                              fontSize: '1rem', // Adjust font size for larger screens
                            },
                            '& .MuiSvgIcon-root': {
                              fontSize: '1.5rem', // Adjust icon size for larger screens
                            },
                          }}
                        />
                      )}
                    />
                  )} */}
              </Grid>
              <Grid item xs={12} sm={6} >
                <TextField
                  fullWidth
                  label="Auditor Name"
                  name="auditorName"
                  value={formData.auditorName}
                  onChange={handleChange}
                  error={!!errors.auditorName}
                  helperText={errors.auditorName}
                  sx={{ fontSize: isSmall ? '0.2rem' : '1rem',
                  //width: isSmall ? '80%' : '100%',
                   }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Employee ID"
                  name="employeeId"
                  value={formData.employeeId}
                  onChange={handleChange}
                  error={!!errors.employeeId}
                  helperText={errors.employeeId}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.reportingPersonDesignation}>
                  <InputLabel id="employee-id-label">Reporting Person Designation</InputLabel>
                  <Select
                    name="reportingPersonDesignation"
                    value={formData.reportingPersonDesignation}
                    onChange={handleChange}
                  >
                    {rpDesignation.map((rpd) => (
                      <MenuItem key={rpd.id} value={rpd.name}>{rpd.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledFormControl fullWidth error={!!errors.typeOfObservation}>
                  <InputLabel>Type of Observation</InputLabel>
                  <Select
                    name="typeOfObservation"
                    value={formData.typeOfObservation}
                    onChange={handleChange}
                  >
                    <MenuItem value="Unsafe Act">Unsafe Act</MenuItem>
                    <MenuItem value="Unsafe Condition">Unsafe Condition</MenuItem>
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Description of observation"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  error={!!errors.description}
                  helperText={errors.description}
                />
              </Grid>
              <Grid item xs={12} sx={{display: 'flex', justifyContent: 'cenetr'}}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="contained-button-file"
                  type="file"
                  onChange={handleImageChange}
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" color="primary" component="span"
                  sx={{
                    ml: isSmall ? 2.5 : 40,
                  }}
                  >
                    Upload Image of Observation
                  </Button>
                </label>
                {formData.image && <Typography variant="body2">{formData.image.name}</Typography>}
              </Grid>
              {/* </Box> */}
              <Grid item xs={12} sm={6}>
                <StyledFormControl fullWidth error={!!errors.severity}>
                  <InputLabel>Severity</InputLabel>
                  <Select
                    name="severity"
                    value={formData.severity}
                    onChange={handleChange}
                  >
                    <MenuItem value="Low">Low</MenuItem>
                    <MenuItem value="Medium">Medium</MenuItem>
                    <MenuItem value="High">High</MenuItem>
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.immediateActionRequired}
                      onChange={handleCheckboxChange}
                      name="immediateActionRequired"
                    />
                  }
                  label="Immediate Action Required"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledFormControl fullWidth error={!!errors.departmentName}>
                  <InputLabel>Department Name</InputLabel>
                  <Select
                    name="departmentName"
                    value={formData.departmentName}
                    onChange={handleChange}
                  >
                    {departments.map((dept) => (
                      <MenuItem key={dept.id} value={dept.name}>{dept.name}</MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledFormControl fullWidth error={!!errors.areaName}>
                  <InputLabel>Area Name</InputLabel>
                  <Select
                    name="areaName"
                    value={formData.areaName}
                    onChange={handleChange}
                  >
                    {areas.map((area) => (
                      <MenuItem key={area.id} value={area.name}>{area.name}</MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Responsible Person"
                  name="responsiblePerson"
                  value={formData.responsiblePerson}
                  onChange={handleChange}
                  error={!!errors.responsiblePerson}
                  helperText={errors.responsiblePerson}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledFormControl fullWidth error={!!errors.responsibleDepartment}>
                  <InputLabel>Responsible Department</InputLabel>
                  <Select
                    name="responsibleDepartment"
                    value={formData.responsibleDepartment}
                    onChange={handleChange}
                  >
                    {rDept.map((rpd) => (
                      <MenuItem key={rpd.id} value={rpd.name}>{rpd.name}</MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Planned Date of Closing"
                  value={formData.plannedDateOfClosing}
                  onChange={(date) => handleDateChange('plannedDateOfClosing', date)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={!!errors.plannedDateOfClosing}
                      helperText={errors.plannedDateOfClosing}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Box mt={3} sx= {{
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
            marginBottom: 2,
            }}>
              <StyledButton
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : 'Submit'}
              </StyledButton>
              <Button
                variant="outlined"
                onClick={() => {
                  setFormData({
                    date: new Date(),
                    auditorName: '',
                    employeeId: '',
                    reportingPersonDesignation: '',
                    typeOfObservation: '',
                    description: '',
                    image: null,
                    severity: '',
                    immediateActionRequired: false,
                    departmentName: '',
                    areaName: '',
                    responsiblePerson: '',
                    responsibleDepartment: '',
                    plannedDateOfClosing: new Date(),
                  });
                  setErrors({});
                }}
              >
                Clear
              </Button>
            </Box>
          </form>

        <Container maxWidth="lg" sx={{mt:4}}>
          <Typography variant="h5" component="div" >
            Unsafe Act Reports
          </Typography>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="fullWidth"
          >
            <Tab label="Pending" />
            <Tab label="Completed" />
          </Tabs>
          <Box mt={3} sx={{paddingBottom:4}} >
            <Grid container spacing={3}>
          {reports
              .filter(report => tabValue === 0 ? report.isPending : !report.isPending)
              .map(report => (
                <StatusCard 
                key={report.id}
                status={report.isPending ?'Pending' : 'Completed'}
                severity={report.severity}
                caseNumber={report.caseNumber}
                date={formatDate(report.date)}
                areaName={report.areaName}
                description={report.description}
                onViewDetails={() => handleModalOpen(report)}
                />
                ))}
            </Grid>
          </Box>
        </Container>
      </Container>

           {/* Details Modal */}
     <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="unsafe-act-modal-title"
      >
        <ModalContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography id="unsafe-act-modal-title" variant="h5" component="h2">
              Unsafe Act Details
            </Typography>
            <IconButton onClick={handleModalClose} size="large">
              <Close />
            </IconButton>
          </Box>
          {selectedUnsafeAct && (
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Case Number</Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUnsafeAct.caseNumber}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Date of Reporting</Typography>
                  <Typography variant="body1" gutterBottom>
                    {formatDate(selectedUnsafeAct.date)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Description</Typography>
                  <Typography variant="body1" paragraph>
                    {selectedUnsafeAct.description}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Area Name</Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUnsafeAct.areaName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Department Name</Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUnsafeAct.departmentName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Auditor Name</Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUnsafeAct.auditorName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">Employee ID</Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUnsafeAct.employeeId}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    Reporting Person Designation
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUnsafeAct.reportingPersonDesignation}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1">
                    Responsible Department
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedUnsafeAct.responsibleDepartment}
                  </Typography>
                </Grid>
              </Grid>

              <Divider sx={{ my: 3 }} />

              {selectedUnsafeAct.image && (
                <Box mt={2} mb={3}>
                  <Typography variant="subtitle1" gutterBottom>
                    Reported Image
                  </Typography>
                  <img
                    src={selectedUnsafeAct.image}
                    alt="Unsafe Act"
                    style={{ width: "100%", borderRadius: "4px" }}
                  />
                </Box>
              )}

              {selectedUnsafeAct.isPending && (
                <Box mt={3}>
                  <Typography variant="h6" gutterBottom>
                    Close Unsafe Act
                  </Typography>
                  <TextField
                    label="Date of Closing"
                    type="date"
                    value={moment(date).format("YYYY-MM-DD")}
                    onChange={handleDateChange}
                    fullWidth
                    margin="normal"
                    disabled={true}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Box mt={2}>
                    <input
                      accept="image/*"
                      id="contained-button-file"
                      type="file"
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="contained-button-file">
                      <Button variant="outlined" component="span" fullWidth>
                        Upload Verification Image
                      </Button>
                    </label>
                    {imageOfVerification && (
                      <Typography variant="body2" color="textSecondary" mt={1}>
                        {imageOfVerification.name}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    mt={3}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCloseUnsafeAct}
                      disabled={isLoading}
                    >
                      {isLoading ? "Closing..." : "Close Unsafe Act"}
                    </Button>
                    {isLoading && <CircularProgress size={24} />}
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </ModalContent>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </LocalizationProvider>
  );
};

export default UnsafeActReport;


