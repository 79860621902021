import React, { useState, useEffect } from "react";
import { Box, CssBaseline, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Avatar, Typography, Collapse, IconButton, Menu, MenuItem, Badge, AppBar, Toolbar, ThemeProvider, styled, Button, Divider,
} from "@mui/material";
import { Home, BarChart, SafetyCheck, Warning, Whatshot, HealthAndSafety, TextSnippet, Engineering, CalendarMonth, Article, Task, ExpandLess, ExpandMore, Logout, Person, Notifications, DocumentScanner, HistoryEdu, AssignmentTurnedIn, Assessment,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import {
  getUIDLocally,
  setLoginStatusLocally,
  setUIDLocally,
} from "./../utils/userUtils";
import { firestore } from "../firebase/firebaseConfig";
import NotificationCard from "../componants/common/NotificationCard";
import { Menu as MenuIcon } from "@mui/icons-material"; // Import the Menu icon
import { useTheme, useMediaQuery } from "@mui/material";
import { Close } from "@material-ui/icons";

const DRAWER_WIDTH = 240;
//const DRAWER_HEIGHT ='100%';
//const COLLAPSED_DRAWER_HEIGHT = 40;
const COLLAPSED_DRAWER_WIDTH = 64;
//const MOBILE_DRAWER_WIDTH = '60%';

// Existing routes and configurations
//const drawerWidth = 240;
//const collapsedDrawerWidth = 90;

const SEVERITY_COLORS = {
  Low: { main: "#4CAF50", light: "#E8F5E9" },
  Medium: { main: "#FF9800", light: "#FFF3E0" },
  High: { main: "#F44336", light: "#FFEBEE" },
};


const routes = [
  {
    path: "/user/user/unsafe-act-report",
    label: <Typography variant="body2">Unsafe Act Report</Typography>,
    icon: <SafetyCheck style={{ fontSize: 20, color: "#212529" }} />,
    permission: "Unsafe Act",
    
  },
  {
    path: "/user/user/near-miss-report",
    label: <Typography variant="body2">Near Miss Report</Typography>,
    icon: <Warning style={{ fontSize: 20, color: "#212529" }} />,
    permission: "Near Miss Report",
  },
  {
    path: "/user/user/fire-injury-report",
    label: <Typography variant="body2">Fire Incident Report</Typography>,
    icon: <Whatshot style={{ fontSize: 20, color: "#212529" }} />,
    permission: "Fire Injury",
  },
  {
    path: "/user/user/accident-report",
    label: <Typography variant="body2">Accident Report</Typography>,
    icon: <HealthAndSafety style={{ fontSize: 20, color: "#212529" }} />,
    permission: "Accident Report",
  },
  {
    path: "/user/user/work-permit",
    label: <Typography variant="body2">Work Permit</Typography>,
    icon: <TextSnippet style={{ fontSize: 20, color: "#212529" }} />,
    permission: "Work Permit",
  },
  {
    path: "/user/user/kaizen-report",
    label: <Typography variant="body2">Kaizen Report</Typography>,
    icon: <BarChart style={{ fontSize: 20, color: "#212529" }} />,
    permission: "Kaizen Reports",
  },
  {
    path: "/user/user/ppe-grievances-report",
    label: <Typography variant="body2">PPE Grievances Report</Typography>,
    icon: <Engineering style={{ fontSize: 20, color: "#212529" }} />,
    permission: "PPE Grievances",
  },
  {
    path: "/user/user/weekly-safety-meeting",
    label: <Typography variant="body2">Weekly Safety Meeting</Typography>,
    icon: <CalendarMonth style={{ fontSize: 20, color: "#212529" }} />,
    permission: "Weekly Safety Meeting",
  },
];

// // Routes for Exame

const exameRoutes = [
  {
    path: "/user/user/exames",
    label: <Typography variant="body2">Training Exams</Typography>,
    icon: <AssignmentTurnedIn style={{ fontSize: 20, color: "#212529" }} />,
  },
  {
    path: "/user/user/result",
    label: <Typography variant="body2">Exam result</Typography>,
    icon: <Assessment style={{ fontSize: 20, color: "#212529" }} />,
  },
];
// //added for managers

const managerRoutes = [
  {
    path: "/user/manager/unsafe-act-list",
    label: <Typography variant="body2">Unsafe Act List</Typography>,
    icon: <Engineering style={{ fontSize: 20, color: "#212529" }} />,
  },
  {
    path: "/user/manager/near-miss-list",
    label: <Typography variant="body2">Near Miss Report List</Typography>,
    icon: <Warning style={{ fontSize: 20, color: "#212529" }} />,
  },
  {
    path: "/user/manager/fire-injury-list",
    label: <Typography variant="body2">Fire Injury List</Typography>,
    icon: <Whatshot style={{ fontSize: 20, color: "#212529" }} />,
  },
  {
    path: "/user/manager/accident-report-list",
    label: <Typography variant="body2">Accident Report List</Typography>,
    icon: <HealthAndSafety style={{ fontSize: 20, color: "#212529" }} />,
  },
  {
    path: "/user/manager/work-permit-list",
    label: <Typography variant="body2">Work Permit List</Typography>,
    icon: <TextSnippet style={{ fontSize: 20, color: "#212529" }} />,
  },
  {
    path: "/user/manager/work-permit-auditor-list",
    label: <Typography variant="body2">Work Permit Audit</Typography>,
    icon: <TextSnippet style={{ fontSize: 20, color: "#212529" }} />,
  },
  {
    path: "/user/manager/kaizen-reports-list",
    label: <Typography variant="body2">Kaizen Report List</Typography>,
    icon: <BarChart style={{ fontSize: 20, color: "#212529" }} />,
  },
  {
    path: "/user/manager/ppe-grievances-list",
    label: <Typography variant="body2">PPE Grievances List</Typography>,
    icon: <SafetyCheck style={{ fontSize: 20, color: "#212529" }} />,
  },
];

//Add audit routes

const auditRoutes = [
  {
    path: "/user/user/audit",
    label: <Typography variant="body2">Audit Form</Typography>,
    icon: <Article style={{ fontSize: 20, color: "#212529" }} />,
  },
  {
    path: "/user/user/audit-report",
    label: <Typography variant="body2">audit Report List</Typography>,
    icon: <Warning style={{ fontSize: 20, color: "#212529" }} />,
  },
];

export default function ProfessionalLayout({ children }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  
  // Responsive breakpoints
  const isXSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const isMedium = useMediaQuery(theme.breakpoints.down('lg'));
  
  // State management
  const [userData, setUserData] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(!isSmall);
  const [openSections, setOpenSections] = useState({
    forms: false,
    manager: false,
    audit: false,
    exams: false,
  });
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  // Update drawer state on screen size change
  useEffect(() => {
    setDrawerOpen(!isSmall);
  }, [isSmall]);

  // Fetch user data and notifications
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = await getUIDLocally();
        const userDocRef = firestore
          .collection("peoples")
          .doc("users")
          .collection("users")
          .doc(userId);
        const userDoc = await userDocRef.get();

        if (userDoc.exists) {
          const userData = { ...userDoc.data(), userId };
          setUserData(userData);

          // Subscribe to notifications
          return firestore
            .collection("company_data")
            .doc(userData.company_name)
            .collection("notifications")
            .orderBy("createdAt", "desc")
            .onSnapshot(
              (snapshot) => {
                if (!snapshot.empty) {
                  const updatedNotifications = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                  }));
                  setNotifications(updatedNotifications);
                  setUnreadCount(updatedNotifications.filter(n => !n.isRead).length);
                }
              },
              (error) => console.error("Error fetching notifications:", error)
            );
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleLogout = async () => {
    try {
      await firebase.auth().signOut();
      setLoginStatusLocally(false);
      setUIDLocally(null);
      setUserData(null);
      window.location.href = "/";
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  // Drawer content
  const DrawerContent = ({closeDrawer}) => (
    <>
      <List>
        <ListItemButton 
          onClick={() => {
            closeDrawer();
            navigate("/user/user")
            
          }}
          sx={{
            px: 2,
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            },
            ml:0.6
          }}
        >
          <ListItemIcon>
            <Home style={{ fontSize: isXSmall ? 20 : 25, color: '#000000' }} />
          </ListItemIcon>
          <ListItemText 
            primary="Dashboard"
            sx={{ 
              opacity: drawerOpen ? 1 : 0,
              display: isSmall && !drawerOpen ? 'none' : 'block',
            }} 
          />
        </ListItemButton>
        {[
          {
            name: "forms",
            label: "Forms",
            icon: <TextSnippet style={{ fontSize: isXSmall ? 20 : 25, color: '#000000' }} />,
            routes: routes,
            show: true
          },
          {
            name: "manager",
            label: "Reports",
            icon: <DocumentScanner style={{ fontSize: isXSmall ? 20 : 25, color: '#000000' }} />,
            routes: managerRoutes,
            show: userData?.role === "manager"
          },
          {
            name: "audit",
            label: "Audit",
            icon: <Task style={{ fontSize: isXSmall ? 20 : 25, color: '#000000' }} />,
            routes: auditRoutes,
            show: true
          },
          {
            name: "exams",
            label: "Exam's",
            icon: <HistoryEdu style={{ fontSize: isXSmall ? 20 : 25, color: '#000000' }} />,
            routes: exameRoutes,
            show: true
          }
        ].filter(section => section.show).map((section) => (
          <React.Fragment key={section.name}>
            <ListItemButton 
              onClick={() => setOpenSections(prev => ({
                ...prev,
                [section.name]: !prev[section.name]
              }))}
              sx={{
                px: 2.5,
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)'
                }
              }}
            >
              <ListItemIcon>{section.icon}</ListItemIcon>
              <ListItemText 
                primary={section.label}
                sx={{ 
                  opacity: drawerOpen ? 1 : 0,
                  display: isSmall && !drawerOpen ? 'none' : 'block'
                }}
              />
              {drawerOpen && (openSections[section.name] ? <ExpandLess /> : <ExpandMore />)}
            </ListItemButton>
            <Collapse in={openSections[section.name] && drawerOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {section.routes.map(route => (
                  (!route.permission || userData?.permissions?.includes(route.permission)) && (
                    <ListItemButton
                      key={route.path}
                      onClick={() => {
                        closeDrawer();
                        navigate(route.path)
                      }}
                      sx={{
                        pl: 4,
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        }
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: 40 }}>
                        {route.icon}
                      </ListItemIcon>
                      <ListItemText primary={route.label} />
                    </ListItemButton>
                  )
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))}
      </List>
    </>
  );

  // const DrawerContent = () => (
  //   <>
  //     <List>
  //       <ListItemButton 
  //         onClick={() => {
  //           //closeDrawer();
  //           navigate("/user/user")
            
  //         }}
  //         sx={{
  //           px: 2,
  //           '&:hover': {
  //             backgroundColor: 'rgba(0, 0, 0, 0.04)'
  //           },
  //           ml:0.6
  //         }}
  //       >
  //         <ListItemIcon>
  //           <Home style={{ fontSize: isXSmall ? 20 : 25, color: '#000000' }} />
  //         </ListItemIcon>
  //         <ListItemText 
  //           primary="Dashboard"
  //           sx={{ 
  //             opacity: drawerOpen ? 1 : 0,
  //             display: isSmall && !drawerOpen ? 'none' : 'block',
  //           }} 
  //           primaryTypographyProps={{fontSize: isSmall ? '0.85rem' : '1.25rem'}}
  //         />
  //       </ListItemButton>

  //       {[
  //         {
  //           name: "forms",
  //           label: "Forms",
  //           icon: <TextSnippet style={{ fontSize: isXSmall ? 20 : 25, color: '#000000' }} />,
  //           routes: routes,
  //           show: true
  //         },
  //         {
  //           name: "manager",
  //           label: "Reports",
  //           icon: <DocumentScanner style={{ fontSize: isXSmall ? 20 : 25, color: '#000000' }} />,
  //           routes: managerRoutes,
  //           show: userData?.role === "manager"
  //         },
  //         {
  //           name: "audit",
  //           label: "Audit",
  //           icon: <Task style={{ fontSize: isXSmall ? 20 : 25, color: '#000000' }} />,
  //           routes: auditRoutes,
  //           show: true
  //         },
  //         {
  //           name: "exams",
  //           label: "Exam's",
  //           icon: <HistoryEdu style={{ fontSize: isXSmall ? 20 : 25, color: '#000000' }} />,
  //           routes: exameRoutes,
  //           show: true
  //         }
  //       ].filter(section => section.show).map((section) => (
  //         <React.Fragment key={section.name}>
  //           <ListItemButton 
  //             onClick={() => setOpenSections(prev => ({
  //               ...prev,
  //               [section.name]: !prev[section.name]
  //             }))}
  //             sx={{
  //               px: 2.5,
  //               '&:hover': {
  //                 backgroundColor: 'rgba(0, 0, 0, 0.04)'
  //               }
  //             }}
  //           >
  //             <ListItemIcon>{section.icon}</ListItemIcon>
  //             <ListItemText 
  //               primary={section.label}
  //               sx={{ 
  //                 opacity: drawerOpen ? 1 : 0,
  //                 display: isSmall && !drawerOpen ? 'none' : 'block',
  //                // fontSize: isSmall ? '0.5rem' : '1.25rem',
  //                 //backgroundColor: 'blue',
  //               }}
  //               primaryTypographyProps={{fontSize: isSmall ? '0.85rem' : '1.25rem'}}
  //             />
  //             {drawerOpen && (openSections[section.name] ? <ExpandLess /> : <ExpandMore />)}
  //           </ListItemButton>
  //           <Collapse in={openSections[section.name] && drawerOpen} timeout="auto" unmountOnExit>
  //             <List component="div" disablePadding>
  //               {section.routes.map(route => (
  //                 (!route.permission || userData?.permissions?.includes(route.permission)) && (
  //                   <ListItemButton
  //                     key={route.path}
  //                     onClick={() => {
  //             //          closeDrawer();
  //                       navigate(route.path)
  //                     }}
  //                     sx={{
  //                       pl: 4,
  //                       '&:hover': {
  //                         backgroundColor: 'rgba(0, 0, 0, 0.04)'
  //                       }
  //                     }}
  //                   >
  //                     <ListItemIcon sx={{ minWidth: 40 }}>
  //                       {route.icon}
  //                     </ListItemIcon>
  //                     <ListItemText primary={route.label} 
  //                      />
  //                   </ListItemButton>
  //                 )
  //               ))}
  //             </List>
  //           </Collapse>
  //         </React.Fragment>
  //       ))}
  //     </List>
  //   </>
  // );


  if (!userData) return null;

  return (
    <ThemeProvider theme={theme}>
      
        <CssBaseline />

        {/* App Bar */}
        <AppBar
          position="fixed"
          sx={{
            // height:73,
            height : isSmall ? 55 : 73,
            width: '100%',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            boxShadow: 'none',
            borderBottom: '1px solid rgba(0,0,0,0.12)',
            ...((!isSmall) && {
              width: `calc(100% - ${drawerOpen ? DRAWER_WIDTH : COLLAPSED_DRAWER_WIDTH}px)`,
              ml: `${drawerOpen ? DRAWER_WIDTH : COLLAPSED_DRAWER_WIDTH}px`,
            }),
          }}
        >

          {/* <Toolbar>
           */}
           <Toolbar >
            <IconButton
              color="inherit"
              edge="start"
              onClick={() => setDrawerOpen(!drawerOpen)}
              sx={{ 
                mr: 2, 
                ...((!isSmall) && { display: 'none' })
              }}
            >
              <MenuIcon />
            </IconButton>

            <Typography variant="h" noWrap component="div" 
            sx={{ 
              flexGrow: 1 ,
              fontSize: isSmall ? 10 : 24,}}>
            {location.pathname.split('/').pop().replace(/-/g, ' ').toUpperCase()} 
            </Typography>

            {/* Notifications */}
            <IconButton
              color="inherit"
              onClick={(e) => setAnchorEl(e.currentTarget)}
              sx={{ mr: 1 }}
            >
              <Badge badgeContent={unreadCount} color="error">
                <Notifications />
              </Badge>
            </IconButton>

            {/* User Menu */}
            <IconButton onClick={(e) => setAnchorElUser(e.currentTarget)}>
              <Avatar
                alt={userData.user_name}
                src="/path/to/profile-picture.jpg"
                sx={{ width: 32, height: 32 }}
              />
            </IconButton>
          </Toolbar>
        </AppBar>

                {/* Drawer for small screens */}
        {isXSmall && (
          <Drawer
            variant="temporary"
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            ModalProps={{ keepMounted: true }}
            sx={{
              '& .MuiDrawer-paper': {
                //width: MOBILE_DRAWER_WIDTH,
                width: '100',
                boxSizing: 'border-box',
              },
            }}
          >
            <Box padding={2} display={'flex'} justifyContent={'flex-start'} alignItems={'center'} >
              <img src="logo192.png" alt='Kavach' width={20} height={20}/>
              <Typography sx={{marginLeft:1}}>  Kavach</Typography>
            </Box>
            <IconButton
            onClick={() => setDrawerOpen(false)}
            sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
            },
          }}
        >
          <Close />
        </IconButton>
        <Divider />
            <DrawerContent closeDrawer={() => setDrawerOpen(false)} />
            {/* <DrawerContent /> */}
          </Drawer>
        )}

        {/* Permanent drawer for larger screens */}
        {!isXSmall && (
          <Drawer
            variant="permanent"
            sx={{
              width: drawerOpen ? DRAWER_WIDTH : COLLAPSED_DRAWER_WIDTH,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerOpen ? DRAWER_WIDTH : COLLAPSED_DRAWER_WIDTH,
                boxSizing: 'border-box',
                transition: theme.transitions.create('width', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
                overflowX: 'hidden',
              },
            }}
            open={drawerOpen}
          >
              <Box padding={2} display={'flex'} justifyContent={'space-evenly'} alignItems={'center'}>
              <img src="/Images/Kavach.png" width={40} height={40}/>
              <Typography component='div' variant="h5">Kavach</Typography>
            </Box>
        <Divider />

            <DrawerContent closeDrawer={() => {}} />
          </Drawer>
        )}

        {/* Main Content */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: { sm: `calc(100% - ${drawerOpen ? DRAWER_WIDTH : COLLAPSED_DRAWER_WIDTH}px)` },
            backgroundColor: theme.palette.background.default,
            marginTop: "20px", 
          }}
        >
          
          {children}
        </Box>

        {/* Menus */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            sx: {
              maxHeight: '80vh',
              width: { xs: '90vw', sm: 320 }
            }
          }}
        >
          {notifications.length === 0 ? (
            <MenuItem>No new notifications</MenuItem>
          ) : (
            notifications.map((notification) => (
              <NotificationCard
                key={notification.id}
                title={notification.title}
                severity={notification.severity}
                SEVERITY_COLORS={SEVERITY_COLORS}
              />
            ))
          )}
        </Menu>
        <Menu
          anchorEl={anchorElUser}
          open={Boolean(anchorElUser)}
          onClose={() => setAnchorElUser(null)}
        >
          <MenuItem onClick={() => navigate("/profile", { state: userData })}>
            <Person sx={{ mr: 1 }} /> Profile
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <Logout sx={{ mr: 1 }} /> Logout
          </MenuItem>
        </Menu>
    </ThemeProvider>
  );
}
