// import React from 'react';
// import { Card, CardContent, Typography, Button, Box, Grid, styled } from '@mui/material';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import WarningIcon from '@mui/icons-material/Warning';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import PropTypes from 'prop-types';
// import { AccessTime } from '@mui/icons-material';
// import { SEVERITY_COLORS, STATUS_COLORS } from '../utils/Constants';

// // Fallback colors for status


// // Styled Components
// const StyledCard = styled(Card)(({ theme }) => ({
//   position: 'relative',
//   height: '100%',
//   borderRadius: theme.shape.borderRadius * 2,
//   boxShadow: theme.shadows[4],
//   transition: 'transform 0.3s ease-in-out',
//   '&:hover': {
//     transform: 'scale(1.02)',
//   },
// }));

// const StatusHeader = styled(Box)(({ theme, severitycolor }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'space-between',
//   padding: theme.spacing(1.5, 2),
//   backgroundColor: severitycolor.light,
//   borderTopLeftRadius: theme.shape.borderRadius * 2,
//   borderTopRightRadius: theme.shape.borderRadius * 2,
// }));

// const StatusLabel = styled(Box)(({ theme, color }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   padding: theme.spacing(0.5, 1.5),
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: color.main,
//   color: theme.palette.common.white,
//   fontWeight: 600,
//   textTransform: 'uppercase',
// }));

// const StatusCard = ({ 
//   status = 'Pending', 
//   severity,   
//   caseNumber = '', 
//   date = '', 
//   areaName = '', 
//   departmentName,
//   areaInchargeName = '', 
//   members = '',
//   description = '', 
//   onViewDetails,
//   additionalDetails
// }) => {
//   // Normalize status and severity
//   const normalizedStatus = (status || 'Pending').charAt(0).toUpperCase() + (status || 'Pending').slice(1).toLowerCase();
//   const normalizedSeverity = severity && (severity.charAt(0).toUpperCase() + severity.slice(1).toLowerCase());

//   // Get colors based on severity or status
//   const severityColor = severity
//     ? SEVERITY_COLORS[normalizedSeverity] || SEVERITY_COLORS.Low
//     : STATUS_COLORS[normalizedStatus] || STATUS_COLORS.Pending;

//   // Determine status icon
//   const StatusIcon = normalizedStatus === 'Pending' ? WarningIcon : CheckCircleIcon;

//   return (
//     <Grid item xs={12} sm={6} md={4}>
//       <StyledCard elevation={4}>
//         <StatusHeader severitycolor={severityColor}>
//           <Box display="flex" alignItems="center" gap={1}>
//             <StatusIcon sx={{ color: severityColor.main }} />
//             <Typography variant="subtitle2" color="textPrimary">
//               {normalizedStatus}
//             </Typography>
//           </Box>
//           <StatusLabel color={severityColor}>
//             <Typography variant="caption">
//               {normalizedSeverity || normalizedStatus}
//             </Typography>
//           </StatusLabel>
//         </StatusHeader>

//         <CardContent sx={{ padding: 3 }}>
//           {additionalDetails && (
//             <Box
//               mb={2}
//               display="flex"
//               justifyContent="center"
//               alignContent="center"
//               alignItems="center"
//               textAlign="center"
//             >
//               <Typography
//                 variant="body2"
//                 color="error"
//                 display="flex"
//                 alignItems="center"
//               >
//                 <AccessTime style={{ fontSize: 20, marginRight: 4 }} />
//                 <strong> {additionalDetails} !</strong>
//               </Typography>
//             </Box>
//           )}
//           <Typography variant="body2" color="textSecondary" gutterBottom>
//             <strong>Case Number:</strong> {caseNumber}
//           </Typography>

//           <Typography variant="body2" color="textSecondary" gutterBottom>
//             <strong>Reported on:</strong> {date}
//           </Typography>

//           <Typography variant="body2" color="textSecondary" gutterBottom>
//             <strong>Department Name:</strong> {departmentName}
//           </Typography>

//           <Typography variant="body2" color="textSecondary" gutterBottom>
//             <strong>Meeting Conducted By:</strong> {areaInchargeName}
//           </Typography>

//           <Typography variant="body2" color="textSecondary" gutterBottom>
//             <strong>Number of Participants:</strong> {members}
//           </Typography>

//           <Typography variant="body2" color="textSecondary" gutterBottom>
//             <strong>Area Name:</strong> {areaName}
//           </Typography>

//           <Typography variant="body2" color="textSecondary" paragraph>
//             <strong>Description:</strong>
//             <Box
//               component="span"
//               display="block"
//               color="text.secondary"
//               sx={{
//                 marginTop: 0.5,
//                 maxHeight: 75,
//                 overflow: 'hidden',
//                 textOverflow: 'ellipsis',
//               }}
//             >
//               {description?.length > 150
//                 ? `${description.substring(0, 150)}...`
//                 : description}
//             </Box>
//           </Typography>

//           <Box display="flex">
//             <Button
//               variant="contained"
//               sx={{
//                 marginTop: 1,
//                 backgroundColor: severityColor.main,
//                 '&:hover': {
//                   backgroundColor: severityColor.main,
//                   opacity: 0.9,
//                 },
//               }}
//               startIcon={<VisibilityIcon />}
//               onClick={onViewDetails}
//               fullWidth
//             >
//               View Details
//             </Button>
//           </Box>
//         </CardContent>
//       </StyledCard>
//     </Grid>
//   );
// };

// StatusCard.propTypes = {
//   status: PropTypes.oneOf(['Pending', 'Completed']),
//   severity: PropTypes.oneOf(['Low', 'Medium', 'High']),
//   caseNumber: PropTypes.string,
//   date: PropTypes.string,
//   areaName: PropTypes.string,
//   description: PropTypes.string,
//   departmentName: PropTypes.string,  // Additional props for specific use cases (e.g., department name)
//   onViewDetails: PropTypes.func.isRequired,
//   additionalDetails: PropTypes.string,
// };

// export default StatusCard;

import React from 'react';
import { Card, CardContent, Typography, Button, Box, Grid, styled } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { AccessTime } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { SEVERITY_COLORS, STATUS_COLORS } from '../utils/Constants';

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  height: '100%',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[4],
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)',
  },
}));

const StatusHeader = styled(Box)(({ theme, severitycolor }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1.5, 2),
  backgroundColor: severitycolor.light,
  borderTopLeftRadius: theme.shape.borderRadius * 2,
  borderTopRightRadius: theme.shape.borderRadius * 2,
}));

const StatusLabel = styled(Box)(({ theme, color }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5, 1.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: color.main,
  color: theme.palette.common.white,
  fontWeight: 600,
  textTransform: 'uppercase',
}));

const ActionButton = styled(Button)(({ theme, bgcolor }) => ({
  marginTop: theme.spacing(1),
  backgroundColor: bgcolor,
  '&:hover': {
    backgroundColor: bgcolor,
    opacity: 0.9,
  },
}));

const StatusCard = ({ 
  status = 'Pending', 
  severity,   
  caseNumber, 
  date, 
  areaName, 
  departmentName,
  areaInchargeName, 
  members,
  description, 
  onViewDetails,
  additionalDetails,
  onInvestigate,
  onClose,
  showInvestigateButton = false,
  showCloseButton = false
}) => {
  // Normalize status and severity
  const normalizedStatus = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  const normalizedSeverity = severity && (severity.charAt(0).toUpperCase() + severity.slice(1).toLowerCase());

  // Get colors based on severity or status
  const severityColor = severity
    ? SEVERITY_COLORS[normalizedSeverity] || SEVERITY_COLORS.Low
    : STATUS_COLORS[normalizedStatus] || STATUS_COLORS.Pending;

  // Determine status icon
  const StatusIcon = normalizedStatus === 'Pending' ? WarningIcon : CheckCircleIcon;

  return (
    <Grid item xs={12} sm={6} md={4}>
      <StyledCard elevation={4}>
        <StatusHeader severitycolor={severityColor}>
          <Box display="flex" alignItems="center" gap={1}>
            <StatusIcon sx={{ color: severityColor.main }} />
            <Typography variant="subtitle2" color="textPrimary">
              {normalizedStatus}
            </Typography>
          </Box>
          <StatusLabel color={severityColor}>
            <Typography variant="caption">
              {normalizedSeverity || normalizedStatus}
            </Typography>
          </StatusLabel>
        </StatusHeader>

        <CardContent sx={{ padding: 3 }}>
          {additionalDetails && (
            <Box
              mb={2}
              display="flex"
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              textAlign="center"
            >
              <Typography
                variant="body2"
                color="error"
                display="flex"
                alignItems="center"
              >
                <AccessTime style={{ fontSize: 20, marginRight: 4 }} />
                <strong> {additionalDetails} !</strong>
              </Typography>
            </Box>
          )}

          {caseNumber && (
            <Typography variant="body2" color="textSecondary" gutterBottom>
              <strong>Case Number:</strong> {caseNumber}
            </Typography>
          )}

          {date && (
            <Typography variant="body2" color="textSecondary" gutterBottom>
              <strong>Reported on:</strong> {date}
            </Typography>
          )}

          {departmentName && (
            <Typography variant="body2" color="textSecondary" gutterBottom>
              <strong>Department Name:</strong> {departmentName}
            </Typography>
          )}

          {areaInchargeName && (
            <Typography variant="body2" color="textSecondary" gutterBottom>
              <strong>Meeting Conducted By:</strong> {areaInchargeName}
            </Typography>
          )}

          {members && (
            <Typography variant="body2" color="textSecondary" gutterBottom>
              <strong>Number of Participants:</strong> {members}
            </Typography>
          )}

          {areaName && (
            <Typography variant="body2" color="textSecondary" gutterBottom>
              <strong>Area Name:</strong> {areaName}
            </Typography>
          )}

          {description && (
            <Typography variant="body2" color="textSecondary" paragraph>
              <strong>Description:</strong>
              <Box
                component="span"
                display="block"
                color="text.secondary"
                sx={{
                  marginTop: 0.5,
                  maxHeight: 75,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {description?.length > 150
                  ? `${description.substring(0, 150)}...`
                  : description}
              </Box>
            </Typography>
          )}

          <Box display="flex" flexDirection="row" gap={1}>
            <ActionButton
              variant="contained"
              bgcolor={severityColor.main}
             
              onClick={onViewDetails}
              fullWidth
            >
              View Details
            </ActionButton>

            {showInvestigateButton && onInvestigate && (
              <ActionButton
                variant="contained"
                bgcolor="#ed6c02" // warning color
                
                onClick={onInvestigate}
                fullWidth
              >
                Investigate
              </ActionButton>
            )}

            {showCloseButton && onClose && (
              <ActionButton
                variant="contained"
                bgcolor="#d32f2f" // error color
              
                onClick={onClose}
                fullWidth
              >
                Close
              </ActionButton>
            )}
          </Box>
        </CardContent>
      </StyledCard>
    </Grid>
  );
};

StatusCard.propTypes = {
  status: PropTypes.oneOf(['Pending', 'Completed']),
  severity: PropTypes.oneOf(['Low', 'Medium', 'High']),
  caseNumber: PropTypes.string,
  date: PropTypes.string,
  areaName: PropTypes.string,
  description: PropTypes.string,
  departmentName: PropTypes.string,
  areaInchargeName: PropTypes.string,
  members: PropTypes.string,
  onViewDetails: PropTypes.func.isRequired,
  onInvestigate: PropTypes.func,
  onClose: PropTypes.func,
  showInvestigateButton: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  additionalDetails: PropTypes.string,
};

export default StatusCard;