import React, { useState, useEffect } from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, Typography, Button, Box, CircularProgress,
  useMediaQuery,
  useTheme
} from '@mui/material';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import * as XLSX from 'xlsx';

const DetailedDataTable = ({ company_name, startDate, endDate, dataType }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const theme = useTheme();
    // Use Material-UI's media query hook to adjust layout for different devices
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    if (company_name) {
      console.log('Component mounted/updated with props:', { company_name, startDate, endDate, dataType });
      fetchDetailedData();
    }
  }, [company_name, startDate, endDate, dataType]);

  const formatDate = (date) => {
    if (!date) return 'N/A';
    
    try {
      if (date instanceof firebase.firestore.Timestamp) {
        return date.toDate().toLocaleDateString();
      }
      if (typeof date === 'string') {
        const parsedDate = new Date(date);
        return isNaN(parsedDate.getTime()) ? 'Invalid Date' : parsedDate.toLocaleDateString();
      }
      if (date.seconds) {
        return new Date(date.seconds * 1000).toLocaleDateString();
      }
      if (date instanceof Date) {
        return date.toLocaleDateString();
      }
      return 'Invalid Date';
    } catch (error) {
      console.error('Date formatting error:', error, 'Date value:', date);
      return 'Invalid Date';
    }
  };

  const fetchDetailedData = async () => {
    try {
      setLoading(true);
      setError(null);
      console.log('Starting data fetch with params:', { company_name, startDate, endDate, dataType });

      if (!company_name) {
        throw new Error('Company name is required');
      }

      const db = firebase.firestore();
      
      // Construct the collection path
      const basePath = `company_data/${company_name}/${company_name}/for_user/unsafe_act`;
      console.log('Using Firestore path:', basePath);
      
      const unsafeActCollection = db.collection(basePath);

      // Get all documents first
      const querySnapshot = await unsafeActCollection.get();
      console.log('Total documents retrieved:', querySnapshot.size);

      const detailedData = [];
      
      // Convert date strings to Date objects for comparison
      const startDateTime = startDate instanceof Date ? startDate : new Date(startDate);
      const endDateTime = endDate instanceof Date ? endDate : new Date(endDate);
      
      // Set time to beginning and end of day
      startDateTime.setHours(0, 0, 0, 0);
      endDateTime.setHours(23, 59, 59, 999);

      querySnapshot.forEach((doc) => {
        try {
          const docData = doc.data();
          console.log('Processing document:', docData.caseNumber);

          // Convert document date to Date object
          let docDate;
          if (docData.date instanceof firebase.firestore.Timestamp) {
            docDate = docData.date.toDate();
          } else if (typeof docData.date === 'string') {
            docDate = new Date(docData.date);
          } else if (docData.date?.seconds) {
            docDate = new Date(docData.date.seconds * 1000);
          } else {
            console.warn('Invalid date format for document:', docData.caseNumber);
            return;
          }

          // Check if the document matches our criteria
          const isRelevant = (
            (dataType === 'unsafeActOpen' && docData.typeOfObservation === 'Unsafe Act' && docData.isPending) ||
            (dataType === 'unsafeActClosed' && docData.typeOfObservation === 'Unsafe Act' && !docData.isPending) ||
            (dataType === 'unsafeConditionOpen' && docData.typeOfObservation === 'Unsafe Condition' && docData.isPending) ||
            (dataType === 'unsafeConditionClosed' && docData.typeOfObservation === 'Unsafe Condition' && !docData.isPending)
          );

          console.log('Document date check:', {
            docDate: docDate.toISOString(),
            startDate: startDateTime.toISOString(),
            endDate: endDateTime.toISOString(),
            isWithinDateRange: docDate >= startDateTime && docDate <= endDateTime,
            isRelevant
          });

          if (isRelevant && docDate >= startDateTime && docDate <= endDateTime) {
            detailedData.push({
              caseNumber: docData.caseNumber || 'N/A',
              date: formatDate(docData.date),
              auditorName: docData.auditorName || 'N/A',
              employeeId: docData.employeeId || 'N/A',
              reportingPersonDesignation: docData.reportingPersonDesignation || 'N/A',
              typeOfObservation: docData.typeOfObservation || 'N/A',
              description: docData.description || 'N/A',
              severity: docData.severity || 'N/A',
              immediateActionRequired: docData.immediateActionRequired || 'N/A',
              departmentName: docData.departmentName || 'N/A',
              areaName: docData.areaName || 'N/A',
              responsiblePerson: docData.responsiblePerson || 'N/A',
              responsibleDepartment: docData.responsibleDepartment || 'N/A',
              plannedDateOfClosing: formatDate(docData.plannedDateOfClosing)
            });
          }
        } catch (docError) {
          console.error('Error processing document:', docError);
        }
      });

      console.log('Filtered data count:', detailedData.length);
      console.log('Sample data:', detailedData.slice(0, 1));
      
      setData(detailedData);
      
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const exportToExcel = () => {
    try {
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "DetailedData");
      XLSX.writeFile(workbook, `${dataType}_detailed_data.xlsx`);
    } catch (error) {
      console.error('Error exporting to Excel:', error);
      setError('Failed to export data to Excel');
    }
  };

  // Debug render
  console.log('Rendering component with data:', { 
    dataLength: data.length, 
    loading, 
    error,
    sampleData: data.slice(0, 1)
  });

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" my={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box my={4}>
        <Typography color="error" variant="body1">
          Error: {error}
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={fetchDetailedData} 
          sx={{ mt: 2 }}
        >
          Retry
        </Button>
      </Box>
    );
  }

 

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant='body1'>
          Detailed Report for {dataType}
        </Typography>
        <Box>
          <Button 
            variant="outlined" 
            color="primary" 
            onClick={fetchDetailedData} 
            sx={{ mr: 1 }}
          >
            Refresh
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={exportToExcel}
            disabled={data.length === 0}
          >
            Export to Excel
          </Button>
        </Box>
      </Box>
      <TableContainer component={Paper} sx={{ maxHeight: '70vh' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Case Number</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Auditor Name</TableCell>
              <TableCell>Employee ID</TableCell>
              <TableCell>Reporting Person Designation</TableCell>
              <TableCell>Type of Observation</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Severity</TableCell>
              <TableCell>Immediate Action Required</TableCell>
              <TableCell>Department Name</TableCell>
              <TableCell>Area Name</TableCell>
              <TableCell>Responsible Person</TableCell>
              <TableCell>Responsible Department</TableCell>
              <TableCell>Planned Date of Closing</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={row.caseNumber || index}>
                <TableCell>{row.caseNumber}</TableCell>
                <TableCell>{row.date}</TableCell>
                <TableCell>{row.auditorName}</TableCell>
                <TableCell>{row.employeeId}</TableCell>
                <TableCell>{row.reportingPersonDesignation}</TableCell>
                <TableCell>{row.typeOfObservation}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.severity}</TableCell>
                <TableCell>{row.immediateActionRequired}</TableCell>
                <TableCell>{row.departmentName}</TableCell>
                <TableCell>{row.areaName}</TableCell>
                <TableCell>{row.responsiblePerson}</TableCell>
                <TableCell>{row.responsibleDepartment}</TableCell>
                <TableCell>{row.plannedDateOfClosing}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DetailedDataTable;