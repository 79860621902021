// import React, { useEffect, useState, useRef } from "react";
// import { Grid, Paper, Typography, Button, Box, CircularProgress, Dialog, DialogContent, DialogTitle, DialogActions, IconButton } from "@mui/material";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import dayjs from "dayjs";
// import { Bar } from "react-chartjs-2";
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
// import firebase from "firebase/compat/app";
// import "firebase/compat/firestore";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
// import DetailedDataTable from "./UnsafeActCondition/DetailedDataTable";
// import DownloadIcon from "@mui/icons-material/Download";
// import FullscreenIcon from "@mui/icons-material/Fullscreen";
// import AspectRatioIcon from "@mui/icons-material/AspectRatio";
// import { styled } from "@mui/material/styles";
// import CloseIcon from "@mui/icons-material/Close";
// import CustomCard from "../../componants/CustomCard";

// ChartJS.register( CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend );

// const FullScreenContainer = styled(Box)(({ theme }) => ({
//   position: "fixed",
//   top: 0,
//   left: 0,
//   right: 0,
//   bottom: 0,
//   backgroundColor: theme.palette.background.paper,
//   zIndex: theme.zIndex.modal,
//   padding: theme.spacing(2),
//   display: "flex",
//   flexDirection: "column",
// }));

// const UnsafeActChart = ({ company_name }) => {
  // const [startDate, setStartDate] = useState(dayjs().startOf("year"));
  // const [endDate, setEndDate] = useState(dayjs());
  // const [loading, setLoading] = useState(false);
  // const [chartData, setChartData] = useState({
  //   monthlyReporting: null,
  //   designationWise: null,
  //   areaWise: null,
  //   departmentWise: null,
  //   responsibleDepartmentWise: null,
  // });
  // const [totals, setTotals] = useState({
  //   unsafeAct: { open: 0, closed: 0 },
  //   unsafeCondition: { open: 0, closed: 0 },
  // });

  // const [openDialog, setOpenDialog] = useState(false);
  // const [selectedDataType, setSelectedDataType] = useState("");

  // const [fullScreenChart, setFullScreenChart] = useState(null);
  // const [chartSizes, setChartSizes] = useState([
  //   { width: "100%", height: 300 },
  //   { width: "100%", height: 300 },
  //   { width: "100%", height: 300 },
  // ]);

  // const [open, setOpen] = useState(false);
  // const [currentChart, setCurrentChart] = useState(null);

  // const chartRefs = useRef([]);

//   const fetchData = async () => {
//     setLoading(true);
//     const db = firebase.firestore();
//     const unsafeActCollection = db
//       .collection("company_data")
//       .doc(company_name)
//       .collection(company_name)
//       .doc("for_user")
//       .collection("unsafe_act");

//     const querySnapshot = await unsafeActCollection.get();

//     const monthlyData = {};
//     const designationData = {};
//     const areaData = {};
//     const departmentData = {};
//     const responsibleDepartmentData = {};
//     let totalUnsafeActOpen = 0;
//     let totalUnsafeActClosed = 0;
//     let totalUnsafeConditionOpen = 0;
//     let totalUnsafeConditionClosed = 0;

//     querySnapshot.forEach((doc) => {
//       const data = doc.data();
//       const {
//         typeOfObservation,
//         date,
//         reportingPersonDesignation,
//         areaName,
//         departmentName,
//         responsibleDepartment,
//         isPending,
//       } = data;

//       const dateObj = date.toDate ? date.toDate() : new Date(date);
//       const month = dateObj.getMonth();
//       const year = dateObj.getFullYear();
//       const monthLabel = `${new Date(year, month).toLocaleString("default", {
//         month: "short",
//       })} ${year}`;

//       if (dateObj >= startDate.toDate() && dateObj <= endDate.toDate()) {
        // // Monthly data
        // if (!monthlyData[monthLabel]) {
        //   monthlyData[monthLabel] = { unsafeAct: 0, unsafeCondition: 0 };
        // }
        // monthlyData[monthLabel][
        //   typeOfObservation === "Unsafe Act" ? "unsafeAct" : "unsafeCondition"
        // ]++;


        // // Designation data
        // if (!designationData[reportingPersonDesignation]) {
        //   designationData[reportingPersonDesignation] = {
        //     unsafeAct: { open: 0, closed: 0 },
        //     unsafeCondition: { open: 0, closed: 0 },
        //   };
        // }
        // const designationCategory =
        //   typeOfObservation === "Unsafe Act" ? "unsafeAct" : "unsafeCondition";
        // designationData[reportingPersonDesignation][designationCategory][
        //   isPending ? "open" : "closed"
        // ]++;

        // // Area data
        // if (!areaData[areaName]) {
        //   areaData[areaName] = {
        //     unsafeAct: { open: 0, closed: 0 },
        //     unsafeCondition: { open: 0, closed: 0 },
        //   };
        // }
        // areaData[areaName][designationCategory][
        //   isPending ? "open" : "closed"
        // ]++;

        // // Department data
        // if (!departmentData[departmentName]) {
        //   departmentData[departmentName] = {
        //     unsafeAct: { open: 0, closed: 0 },
        //     unsafeCondition: { open: 0, closed: 0 },
        //   };
        // }
        // departmentData[departmentName][designationCategory][
        //   isPending ? "open" : "closed"
        // ]++;

        // // Responsible Department data
        // if (!responsibleDepartmentData[responsibleDepartment]) {
        //   responsibleDepartmentData[responsibleDepartment] = {
        //     unsafeAct: { open: 0, closed: 0 },
        //     unsafeCondition: { open: 0, closed: 0 },
        //   };
        // }
        // responsibleDepartmentData[responsibleDepartment][designationCategory][
        //   isPending ? "open" : "closed"
        // ]++;

        // // Totals
        // if (typeOfObservation === "Unsafe Act") {
        //   isPending ? totalUnsafeActOpen++ : totalUnsafeActClosed++;
        // } else {
        //   isPending ? totalUnsafeConditionOpen++ : totalUnsafeConditionClosed++;
        // }
//       }
//     });

//     const sortedMonthLabels = Object.keys(monthlyData).sort(
//       (a, b) => new Date(a) - new Date(b)
//     );

//     setChartData({
//       monthlyReporting: {
//         labels: sortedMonthLabels,
//         datasets: [
//           {
//             label: "Unsafe Act",
//             data: sortedMonthLabels.map(
//               (label) => monthlyData[label].unsafeAct
//             ),
//             borderColor: "rgb(255, 99, 132)",
//             backgroundColor: "rgba(255, 99, 132, 0.5)",
//           },
//           {
//             label: "Unsafe Condition",
//             data: sortedMonthLabels.map(
//               (label) => monthlyData[label].unsafeCondition
//             ),
//             borderColor: "rgb(53, 162, 235)",
//             backgroundColor: "rgba(53, 162, 235, 0.5)",
//           },
//         ],
//       },
//       designationWise: createStackedGroupedChartData(designationData),
//       areaWise: createStackedGroupedChartData(areaData),
//       departmentWise: createStackedGroupedChartData(departmentData),
//       responsibleDepartmentWise: createStackedGroupedChartData(
//         responsibleDepartmentData
//       ),
//     });

//     setTotals({
//       unsafeAct: { open: totalUnsafeActOpen, closed: totalUnsafeActClosed },
//       unsafeCondition: {
//         open: totalUnsafeConditionOpen,
//         closed: totalUnsafeConditionClosed,
//       },
//     });

//     setLoading(false);
//   };

//   const createStackedGroupedChartData = (designationData) => {
//     return {
//       labels: Object.keys(designationData),
//       datasets: [
//         {
//           label: "Unsafe Act Open",
//           data: Object.values(designationData).map((d) => d.unsafeAct.open),
//           backgroundColor: colorPalette.unsafeActOpen,
//           stack: "Stack 0",
//         },
//         {
//           label: "Unsafe Act Closed",
//           data: Object.values(designationData).map((d) => d.unsafeAct.closed),
//           backgroundColor: colorPalette.unsafeActClosed,
//           stack: "Stack 0",
//         },
//         {
//           label: "Unsafe Condition Open",
//           data: Object.values(designationData).map(
//             (d) => d.unsafeCondition.open
//           ),
//           backgroundColor: colorPalette.unsafeConditionOpen,
//           stack: "Stack 1",
//         },
//         {
//           label: "Unsafe Condition Closed",
//           data: Object.values(designationData).map(
//             (d) => d.unsafeCondition.closed
//           ),
//           backgroundColor: colorPalette.unsafeConditionClosed,
//           stack: "Stack 1",
//         },
//       ],
//     };
//   };

//   useEffect(() => {
//     fetchData();
//   }, [company_name, startDate, endDate]);

//   const handleExportPDF = () => {
//     const pdf = new jsPDF("p", "mm", "a4");
//     const pageHeight = pdf.internal.pageSize.height;
//     const pageWidth = pdf.internal.pageSize.width;
//     const margin = 10;
//     let yOffset = margin;

//     const addChartToPDF = (chartElement, callback) => {
//       if (!chartElement) {
//         console.error("Invalid chart element");
//         callback();
//         return;
//       }

//       html2canvas(chartElement, { useCORS: true }).then((canvas) => {
//         const imgData = canvas.toDataURL("image/png");
//         const imgWidth = pageWidth - 2 * margin;
//         const imgHeight = (canvas.height * imgWidth) / canvas.width;

//         if (yOffset + imgHeight + margin > pageHeight) {
//           pdf.addPage();
//           yOffset = margin;
//         }

//         pdf.addImage(imgData, "PNG", margin, yOffset, imgWidth, imgHeight);
//         yOffset += imgHeight + margin;

//         callback();
//       });
//     };

//     const processCharts = (index) => {
//       if (index < chartRefs.current.length) {
//         const chartElement = chartRefs.current[index];
//         if (chartElement) {
//           addChartToPDF(chartElement, () => {
//             processCharts(index + 1);
//           });
//         } else {
//           console.error(`Chart element at index ${index} is undefined`);
//           processCharts(index + 1);
//         }
//       } else {
//         pdf.save("Unsafe_Act_charts.pdf");
//       }
//     };

//     processCharts(0);
//   };

//   const chartOptions = {
//     responsive: true,
//     scales: {
//       x: { stacked: true },
//       y: { stacked: true },
//     },
//     plugins: {
//       legend: { position: "top" },
//       title: { display: true, text: "Unsafe Act and Condition Charts" },
//     },
//   };

//   const handleTotalClick = (dataType) => {
//     setSelectedDataType(dataType);
//     setOpenDialog(true);
//   };

//   const handleCloseDialog = () => {
//     setOpenDialog(false);
//   };

//   const lineChartOptions = {
//     responsive: true,
//     maintainAspectRatio: false,
//     animation: {
//       duration: 1000,
//       easing: "easeInOutQuad",
//     },
//     scales: {
//       x: {
//         title: { display: true, text: "Month Year" },
//         grid: { display: false },
//       },
//       y: {
//         title: { display: true, text: "Number of meetings" },
//         beginAtZero: true,
//       },
//     },
//     plugins: {
//       legend: { display: false },
//       tooltip: { mode: "index", intersect: false },
//     },
//   };

//   const colorPalette = {
//     unsafeActOpen: "rgba(33, 150, 243, 0.8)", // Blue
//     unsafeActClosed: "rgba(33, 150, 243, 0.5)", // Light Blue
//     unsafeConditionOpen: "rgba(76, 175, 80, 0.8)", // Green
//     unsafeConditionClosed: "rgba(76, 175, 80, 0.5)", // Light Green
//   };

//   const barChartOptions = {
//     responsive: true,
//     maintainAspectRatio: false,
//     scales: {
//       x: {
//         stacked: true,
//         title: {
//           display: true,
//           text: "Categories",
//           font: {
//             family: "Poppins",
//             size: 14,
//             weight: "600",
//           },
//           color: "#333",
//         },
//         grid: {
//           display: false,
//         },
//         ticks: {
//           font: {
//             family: "Poppins",
//             size: 12,
//           },
//           color: "#555",
//         },
//       },
//       y: {
//         stacked: true,
//         title: {
//           display: true,
//           text: "Values",
//           font: {
//             family: "Poppins",
//             size: 14,
//             weight: "600",
//           },
//           color: "#333",
//         },
//         grid: {
//           color: "#e0e0e0",
//         },
//         ticks: {
//           font: {
//             family: "Poppins",
//             size: 12,
//           },
//           color: "#555",
//         },
//       },
//     },
//     plugins: {
//       legend: {
//         position: "top",
//         labels: {
//           font: {
//             family: "Poppins",
//             size: 12,
//             weight: "500",
//           },
//           color: "#333",
//         },
//       },
//       tooltip: {
//         mode: "index",
//         intersect: false,
//         callbacks: {
//           label: (context) => `${context.dataset.label}: ${context.raw}`,
//         },
//         backgroundColor: "rgba(0, 0, 0, 0.8)",
//         titleFont: {
//           family: "Poppins",
//           size: 14,
//           weight: "600",
//         },
//         bodyFont: {
//           family: "Poppins",
//           size: 12,
//           weight: "400",
//         },
//       },
//     },
//   };

//   const handleFullScreen = (index) => {
//     if (fullScreenChart === index) {
//       setFullScreenChart(null);
//     } else {
//       setFullScreenChart(index);
//     }
//   };

//   const handleResize = (index) => {
//     const newSizes = [...chartSizes];
//     newSizes[index] = {
//       width: newSizes[index].width === "100%" ? "80%" : "100%",
//       height: newSizes[index].height === 300 ? 400 : 300,
//     };
//     setChartSizes(newSizes);
//   };


//   const handleClickOpen = (index, title, chartComponent) => {
//     setCurrentChart({ index, title, component: chartComponent });
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setCurrentChart(null);
//   };

//   const renderChart = (index, title, chartComponent) => {
//     const isFullScreen = fullScreenChart === index;
//     const ChartContainer = isFullScreen ? FullScreenContainer : Paper;

//     return (
  //     <ChartContainer elevation={2} sx={{ p: 2, position: "relative" }}>
  //       <Box sx={{ position: "absolute", top: 10, right: 10, zIndex: 1 }}>
  //         <IconButton
  //           onClick={() => handleClickOpen(index, title, chartComponent)}
  //         >
  //           <FullscreenIcon />
  //         </IconButton>
  //         {!isFullScreen && (
  //           <IconButton onClick={() => handleResize(index)}>
  //             <AspectRatioIcon />
  //           </IconButton>
  //         )}
  //       </Box>
  //       <Typography
  //         variant="h6"
  //         align="center"
  //         gutterBottom
  //         sx={{ fontSize: "0.895rem" }}
  //       >
  //         {title}
  //       </Typography>
  //       <Box
  //         sx={{
  //           height: isFullScreen
  //             ? "calc(100% - 60px)"
  //             : chartSizes[index].height,
  //           width: "100%",
  //         }}
  //       >
  //         {chartComponent}
  //       </Box>
  //     </ChartContainer>
  //   );
  // };

  // return (
    // <Box p={2}>
    //   <Typography variant="h4" gutterBottom fontWeight={"bold"}>
    //     Unsafe Act and Condition Report
    //   </Typography>
    //   <Box mb={2}>
    //     <Grid container spacing={2}>
    //       <CustomCard
    //         URL={"/images/Falling.png"}
    //         height={80}
    //         bannerText="Unsafe Act"
    //         bannerColor="#ff5722"
    //         bannerTextColor="#f8f9fa"
    //         openTextColor="#f8f9fa"
    //         closedTextColor="fff"
    //         onOpenClick={() => handleTotalClick("unsafeActOpen")}
    //         onCloseClick={() => handleTotalClick("unsafeActClosed")}
    //         openTitle="Open"
    //         openValue={totals.unsafeAct.open}
    //         closedTitle="Closed"
    //         closedValue={totals.unsafeAct.closed}
    //         subtitleColor="text.secondary"
    //       />
    //        <CustomCard
    //        URL={"/images/Unsafestares.png"}
    //        height={80}
    //         bannerText="Unsafe Condition"
    //         bannerColor="#ff5722"
    //         bannerTextColor="#f8f9fa"
    //         openTextColor="#f8f9fa"
    //         closedTextColor="#f8f9fa"
    //         onOpenClick={() => handleTotalClick("unsafeConditionOpen")}
    //         onCloseClick={() => handleTotalClick("unsafeConditionClosed")}
    //         openTitle="Open"
    //         openValue={totals.unsafeCondition.open}
    //         closedTitle="Closed"
    //         closedValue={totals.unsafeCondition.closed}
    //         subtitleColor="text.secondary"
    //       />
    //     </Grid>
    //   </Box>
    //   <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
    //     <DialogTitle>
    //       <div
    //         style={{
    //           display: "flex",
    //           justifyContent: "space-between",
    //           alignItems: "center",
    //         }}
    //       >
    //         <Typography variant="h6">
    //           {currentChart?.title} - Full Screen
    //         </Typography>
    //         <IconButton onClick={handleClose}>
    //           <CloseIcon />
    //         </IconButton>
    //       </div>
    //     </DialogTitle>
    //     <DialogContent>
    //       {currentChart?.component}
    //       <Typography variant="body1" sx={{ mt: 2 }}>
    //         Additional information about this chart can be displayed here.
    //       </Typography>
    //     </DialogContent>
    //   </Dialog>

    //   <Box
    //     sx={{
    //       mb: 4,
    //       display: "flex",
    //       flexDirection: { xs: "column", md: "row" },
    //       alignItems: "center",
    //       justifyContent: "space-between",
    //     }}
    //   >
    //     <Box
    //       sx={{
    //         display: "flex",
    //         flexDirection: { xs: "column", sm: "row" },
    //         gap: 2,
    //         mt: 2,
    //       }}
    //     >
    //       <LocalizationProvider dateAdapter={AdapterDayjs}>
    //         <DatePicker
    //           label="Start Date"
    //           value={startDate}
    //           onChange={(newValue) => setStartDate(newValue)}
    //         />
    //       </LocalizationProvider>
          
    //       <LocalizationProvider dateAdapter={AdapterDayjs}>
    //         <DatePicker
    //           label="End Date"
    //           value={endDate}
    //           onChange={(newValue) => setEndDate(newValue)}
    //         />
    //       </LocalizationProvider>
          
    //     </Box>

    //     <Button
    //       variant="contained"
    //       color="primary"
    //       onClick={handleExportPDF}
    //       startIcon={<DownloadIcon />}
    //       sx={{ minWidth: 150 }}
    //     >
    //       Export as PDF
    //     </Button>
    //   </Box>

    //   {loading ? (
    //     <Box display="flex" justifyContent="center">
    //       <CircularProgress />
    //     </Box>
    //   ) : (
    //     <>
    //       <Grid container spacing={3}>
    //         <Grid item xs={12} md={12} ref={(el) => (chartRefs.current[1] = el)}>
    //           {renderChart(
    //             1,
    //             "Designation-wise Unsafe Act and Condition",
    //             chartData.designationWise ? (
    //               <Bar
    //                 data={chartData.designationWise}
    //                 options={barChartOptions}
    //               />
    //             ) : (
    //               <Typography align="center" color="error">
    //                 No data available
    //               </Typography>
    //             )
    //           )}
    //         </Grid>

    //         <Grid item xs={12} md={6} ref={(el) => (chartRefs.current[2] = el)}>
    //           {renderChart(
    //             2,
    //             "Area-wise Unsafe Act and Condition",
    //             chartData.areaWise ? (
    //               <Bar data={chartData.areaWise} options={barChartOptions} />
    //             ) : (
    //               <Typography align="center" color="error">
    //                 No data available
    //               </Typography>
    //             )
    //           )}
    //         </Grid>
    //         <Grid item xs={12} md={6} ref={(el) => (chartRefs.current[3] = el)}>
    //           {renderChart(
    //             2,
    //             "Department-wise Unsafe Act and Condition",
    //             chartData.departmentWise ? (
    //               <Bar
    //                 data={chartData.departmentWise}
    //                 options={barChartOptions}
    //               />
    //             ) : (
    //               <Typography align="center" color="error">
    //                 No data available
    //               </Typography>
    //             )
    //           )}
    //         </Grid>
    //         <Grid item xs={12} md={12} ref={(el) => (chartRefs.current[4] = el)}>
    //           {renderChart(
    //             2,
    //             "Responsible Department-wise Unsafe Act and Condition",
    //             chartData.responsibleDepartmentWise ? (
    //               <Bar
    //                 data={chartData.responsibleDepartmentWise}
    //                 options={barChartOptions}
    //               />
    //             ) : (
    //               <Typography align="center" color="error">
    //                 No data available
    //               </Typography>
    //             )
    //           )}
    //         </Grid>
    //       </Grid>
    //       <Dialog
    //         open={openDialog}
    //         onClose={handleCloseDialog}
    //         maxWidth="lg"
    //         fullWidth
    //       >
    //         <DialogTitle>Detailed Report</DialogTitle>
    //         <DialogContent>
    //           <DetailedDataTable
    //             company_name={company_name}
    //             startDate={formatDate(startDate)}
    //             endDate={formatDate(endDate}
    //             dataType={selectedDataType}
    //           />
    //         </DialogContent>
    //         <DialogActions>
    //           <Button onClick={handleCloseDialog} color="primary">
    //             Close
    //           </Button>
    //         </DialogActions>
    //       </Dialog>
    //     </>
    //   )}
    // </Box>
//   );
// };

// export default UnsafeActChart;

import React, { useEffect, useState, useRef } from "react";
import { Grid, Paper, Typography, Button, Box, CircularProgress, Dialog, DialogContent, DialogTitle, DialogActions, IconButton, useMediaQuery } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import DetailedDataTable from "./UnsafeActCondition/DetailedDataTable";
import DownloadIcon from "@mui/icons-material/Download";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import CustomCard from "../../componants/CustomCard";

ChartJS.register( CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend );

const FullScreenContainer = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: theme.zIndex.modal,
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
}));

// Helper function to safely format Firebase timestamps
const formatTimestamp = (timestamp) => {
  if (!timestamp) return "";
  
  // Handle Firebase Timestamp
  if (timestamp.toDate) {
    return timestamp.toDate();
  }
  
  // Handle dayjs object
  if (dayjs.isDayjs(timestamp)) {
    return timestamp.toDate();
  }
  
  // Handle Date object
  if (timestamp instanceof Date) {
    return timestamp;
  }
  
  // Handle string or timestamp number
  return new Date(timestamp);
};

const UnsafeActChart = ({ company_name }) => {

  // Use Material-UI's media query hook to adjust layout for different devices
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [startDate, setStartDate] = useState(dayjs().startOf("year"));
  const [endDate, setEndDate] = useState(dayjs());
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState({
    monthlyReporting: null,
    designationWise: null,
    areaWise: null,
    departmentWise: null,
    responsibleDepartmentWise: null,
  });
  const [totals, setTotals] = useState({
    unsafeAct: { open: 0, closed: 0 },
    unsafeCondition: { open: 0, closed: 0 },
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDataType, setSelectedDataType] = useState("");

  const [fullScreenChart, setFullScreenChart] = useState(null);
  const [chartSizes, setChartSizes] = useState([
    { width: "100%", height: 300 },
    { width: "100%", height: 300 },
    { width: "100%", height: 300 },
  ]);

  const [open, setOpen] = useState(false);
  const [currentChart, setCurrentChart] = useState(null);

  const chartRefs = useRef([]);


  const fetchData = async () => {
    setLoading(true);
    const db = firebase.firestore();
    const unsafeActCollection = db
      .collection("company_data")
      .doc(company_name)
      .collection(company_name)
      .doc("for_user")
      .collection("unsafe_act");

    const querySnapshot = await unsafeActCollection.get();

    const monthlyData = {};
    const designationData = {};
    const areaData = {};
    const departmentData = {};
    const responsibleDepartmentData = {};
    let totalUnsafeActOpen = 0;
    let totalUnsafeActClosed = 0;
    let totalUnsafeConditionOpen = 0;
    let totalUnsafeConditionClosed = 0;

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const {
        typeOfObservation,
        date,
        reportingPersonDesignation,
        areaName,
        departmentName,
        responsibleDepartment,
        isPending,
      } = data;

       // Safely convert the date using our helper function
       const dateObj = formatTimestamp(date);
        
       // Skip invalid dates
       if (!dateObj || isNaN(dateObj.getTime())) {
         console.warn("Invalid date found in document:", doc.id);
         return;
       }

       const month = dateObj.getMonth();
       const year = dateObj.getFullYear();
       const monthLabel = `${new Date(year, month).toLocaleString("default", {
         month: "short",
       })} ${year}`;

       // Convert startDate and endDate to Date objects for comparison
       const startDateObj = startDate.toDate();
       const endDateObj = endDate.toDate();

      if (dateObj >= startDateObj && dateObj <= endDateObj) {
              // Monthly data
              if (!monthlyData[monthLabel]) {
                monthlyData[monthLabel] = { unsafeAct: 0, unsafeCondition: 0 };
              }
              monthlyData[monthLabel][
                typeOfObservation === "Unsafe Act" ? "unsafeAct" : "unsafeCondition"
              ]++;
      
      
              // Designation data
              if (!designationData[reportingPersonDesignation]) {
                designationData[reportingPersonDesignation] = {
                  unsafeAct: { open: 0, closed: 0 },
                  unsafeCondition: { open: 0, closed: 0 },
                };
              }
              const designationCategory =
                typeOfObservation === "Unsafe Act" ? "unsafeAct" : "unsafeCondition";
              designationData[reportingPersonDesignation][designationCategory][
                isPending ? "open" : "closed"
              ]++;
      
              // Area data
              if (!areaData[areaName]) {
                areaData[areaName] = {
                  unsafeAct: { open: 0, closed: 0 },
                  unsafeCondition: { open: 0, closed: 0 },
                };
              }
              areaData[areaName][designationCategory][
                isPending ? "open" : "closed"
              ]++;
      
              // Department data
              if (!departmentData[departmentName]) {
                departmentData[departmentName] = {
                  unsafeAct: { open: 0, closed: 0 },
                  unsafeCondition: { open: 0, closed: 0 },
                };
              }
              departmentData[departmentName][designationCategory][
                isPending ? "open" : "closed"
              ]++;
      
              // Responsible Department data
              if (!responsibleDepartmentData[responsibleDepartment]) {
                responsibleDepartmentData[responsibleDepartment] = {
                  unsafeAct: { open: 0, closed: 0 },
                  unsafeCondition: { open: 0, closed: 0 },
                };
              }
              responsibleDepartmentData[responsibleDepartment][designationCategory][
                isPending ? "open" : "closed"
              ]++;
      
              // Totals
              if (typeOfObservation === "Unsafe Act") {
                isPending ? totalUnsafeActOpen++ : totalUnsafeActClosed++;
              } else {
                isPending ? totalUnsafeConditionOpen++ : totalUnsafeConditionClosed++;
              } 
      }
    });

    // ... rest of the function remains the same
        const sortedMonthLabels = Object.keys(monthlyData).sort(
      (a, b) => new Date(a) - new Date(b)
    );

    setChartData({
      monthlyReporting: {
        labels: sortedMonthLabels,
        datasets: [
          {
            label: "Unsafe Act",
            data: sortedMonthLabels.map(
              (label) => monthlyData[label].unsafeAct
            ),
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
          {
            label: "Unsafe Condition",
            data: sortedMonthLabels.map(
              (label) => monthlyData[label].unsafeCondition
            ),
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
        ],
      },
      designationWise: createStackedGroupedChartData(designationData),
      areaWise: createStackedGroupedChartData(areaData),
      departmentWise: createStackedGroupedChartData(departmentData),
      responsibleDepartmentWise: createStackedGroupedChartData(
        responsibleDepartmentData
      ),
    });

    setTotals({
      unsafeAct: { open: totalUnsafeActOpen, closed: totalUnsafeActClosed },
      unsafeCondition: {
        open: totalUnsafeConditionOpen,
        closed: totalUnsafeConditionClosed,
      },
    });

    setLoading(false);
  };

  const createStackedGroupedChartData = (designationData) => {
    return {
      labels: Object.keys(designationData),
      datasets: [
        {
          label: "Unsafe Act Open",
          data: Object.values(designationData).map((d) => d.unsafeAct.open),
          backgroundColor: colorPalette.unsafeActOpen,
          stack: "Stack 0",
        },
        {
          label: "Unsafe Act Closed",
          data: Object.values(designationData).map((d) => d.unsafeAct.closed),
          backgroundColor: colorPalette.unsafeActClosed,
          stack: "Stack 0",
        },
        {
          label: "Unsafe Condition Open",
          data: Object.values(designationData).map(
            (d) => d.unsafeCondition.open
          ),
          backgroundColor: colorPalette.unsafeConditionOpen,
          stack: "Stack 1",
        },
        {
          label: "Unsafe Condition Closed",
          data: Object.values(designationData).map(
            (d) => d.unsafeCondition.closed
          ),
          backgroundColor: colorPalette.unsafeConditionClosed,
          stack: "Stack 1",
        },
      ],
    };
  };

  useEffect(() => {
    fetchData();
  }, [company_name, startDate, endDate]);

  const handleExportPDF = () => {
    const pdf = new jsPDF("p", "mm", "a4");
    const pageHeight = pdf.internal.pageSize.height;
    const pageWidth = pdf.internal.pageSize.width;
    const margin = 10;
    let yOffset = margin;

    const addChartToPDF = (chartElement, callback) => {
      if (!chartElement) {
        console.error("Invalid chart element");
        callback();
        return;
      }

      html2canvas(chartElement, { useCORS: true }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = pageWidth - 2 * margin;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        if (yOffset + imgHeight + margin > pageHeight) {
          pdf.addPage();
          yOffset = margin;
        }

        pdf.addImage(imgData, "PNG", margin, yOffset, imgWidth, imgHeight);
        yOffset += imgHeight + margin;

        callback();
      });
    };

    const processCharts = (index) => {
      if (index < chartRefs.current.length) {
        const chartElement = chartRefs.current[index];
        if (chartElement) {
          addChartToPDF(chartElement, () => {
            processCharts(index + 1);
          });
        } else {
          console.error(`Chart element at index ${index} is undefined`);
          processCharts(index + 1);
        }
      } else {
        pdf.save("Unsafe_Act_charts.pdf");
      }
    };

    processCharts(0);
  };

  const chartOptions = {
    responsive: true,
    scales: {
      x: { stacked: true },
      y: { stacked: true },
    },
    plugins: {
      legend: { position: "top" },
      title: { display: true, text: "Unsafe Act and Condition Charts" },
    },
  };

  const handleTotalClick = (dataType) => {
    setSelectedDataType(dataType);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 1000,
      easing: "easeInOutQuad",
    },
    scales: {
      x: {
        title: { display: true, text: "Month Year" },
        grid: { display: false },
      },
      y: {
        title: { display: true, text: "Number of meetings" },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: { mode: "index", intersect: false },
    },
  };

  const colorPalette = {
    unsafeActOpen: "rgba(33, 150, 243, 0.8)", // Blue
    unsafeActClosed: "rgba(33, 150, 243, 0.5)", // Light Blue
    unsafeConditionOpen: "rgba(76, 175, 80, 0.8)", // Green
    unsafeConditionClosed: "rgba(76, 175, 80, 0.5)", // Light Green
  };

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Categories",
          font: {
            family: "Poppins",
            size: 14,
            weight: "600",
          },
          color: "#333",
        },
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: "Poppins",
            size: 12,
          },
          color: "#555",
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: "Values",
          font: {
            family: "Poppins",
            size: 14,
            weight: "600",
          },
          color: "#333",
        },
        grid: {
          color: "#e0e0e0",
        },
        ticks: {
          font: {
            family: "Poppins",
            size: 12,
          },
          color: "#555",
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            family: "Poppins",
            size: 12,
            weight: "500",
          },
          color: "#333",
        },
      },
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw}`,
        },
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        titleFont: {
          family: "Poppins",
          size: 14,
          weight: "600",
        },
        bodyFont: {
          family: "Poppins",
          size: 12,
          weight: "400",
        },
      },
    },
  };

  const handleFullScreen = (index) => {
    if (fullScreenChart === index) {
      setFullScreenChart(null);
    } else {
      setFullScreenChart(index);
    }
  };

  const handleResize = (index) => {
    const newSizes = [...chartSizes];
    newSizes[index] = {
      width: newSizes[index].width === "100%" ? "80%" : "100%",
      height: newSizes[index].height === 300 ? 400 : 300,
    };
    setChartSizes(newSizes);
  };


  const handleClickOpen = (index, title, chartComponent) => {
    setCurrentChart({ index, title, component: chartComponent });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentChart(null);
  };

  const renderChart = (index, title, chartComponent) => {
    const isFullScreen = fullScreenChart === index;
    const ChartContainer = isFullScreen ? FullScreenContainer : Paper;

        return (
      <ChartContainer elevation={2} sx={{ p: 2, position: "relative" }}>
        <Box sx={{ position: "absolute", top: 10, right: 10, zIndex: 1 }}>
          <IconButton
            onClick={() => handleClickOpen(index, title, chartComponent)}
          >
            <FullscreenIcon />
          </IconButton>
          {!isFullScreen && (
            <IconButton onClick={() => handleResize(index)}>
              <AspectRatioIcon />
            </IconButton>
          )}
        </Box>
        <Typography
          variant="h6"
          align="center"
          gutterBottom
          sx={{ fontSize: "0.895rem" }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            height: isFullScreen
              ? "calc(100% - 60px)"
              : chartSizes[index].height,
            width: "100%",
          }}
        >
          {chartComponent}
        </Box>
      </ChartContainer>
    );
  };

  // // Helper function to format dates for display
  // const formatDate = (date) => {
  //   if (!date) return '';
  //   if (date.toDate) {
  //     // Handle Firebase Timestamp
  //     return date.toDate().toLocaleDateString();
  //   }
  //   if (date instanceof Date) {
  //     // Handle JavaScript Date
  //     return date.toLocaleDateString();
  //   }
  //   // Handle string or other formats
  //   return new Date(date).toLocaleDateString();
  // };
  const formattedStartDate = startDate ? dayjs(startDate).format('YYYY-MM-DD') : '';
  const formattedEndDate = endDate ? dayjs(endDate).format('YYYY-MM-DD') : '';
  
  // Modify the DetailedDataTable props to pass formatted dates
  return (
    <Box p={2}>
    <Typography gutterBottom fontWeight={"bold"}
      variant={isSmallScreen ? "h6" : "h6"} 
      sx={{ fontSize: isSmallScreen ? "0.9rem" : "1.5rem" }}
      >
      Unsafe Act and Condition Report
    </Typography>
    <Box mb={2}>
      <Grid container spacing={2}>
        <CustomCard
          bannerText="Unsafe Act"
          bannerColor="#ff5722"
          bannerTextColor="#f8f9fa"
          openTextColor="#f8f9fa"
          closedTextColor="fff"
          onOpenClick={() => handleTotalClick("unsafeActOpen")}
          onCloseClick={() => handleTotalClick("unsafeActClosed")}
          openTitle="Open"
          openValue={totals.unsafeAct.open}
          closedTitle="Closed"
          closedValue={totals.unsafeAct.closed}
          subtitleColor="text.secondary"
        />
         <CustomCard
          bannerText="Unsafe Condition"
          bannerColor="#ff5722"
          bannerTextColor="#f8f9fa"
          openTextColor="#f8f9fa"
          closedTextColor="#f8f9fa"
          onOpenClick={() => handleTotalClick("unsafeConditionOpened")}
          onCloseClick={() => handleTotalClick("unsafeConditionClosed")}
          openTitle="Open"
          openValue={totals.unsafeCondition.open}
          closedTitle="Closed"
          closedValue={totals.unsafeCondition.closed}
          subtitleColor="text.secondary"
        />
      </Grid>
    </Box>
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            {currentChart?.title} - Full Screen
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        {currentChart?.component}
        <Typography variant="body1" sx={{ mt: 2 }}>
          Additional information about this chart can be displayed here.
        </Typography>
      </DialogContent>
    </Dialog>

    <Box
      sx={{
        mb: 4,
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: 2,
          mt: 2,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            slotProps={{
              textField: {
                fullWidth: isSmallScreen, // Full width on small screens
                sx: {
                  "& .MuiInputBase-input": {
                    fontSize: isSmallScreen ? "0.6rem" : "1rem",
                  },
                },
              },
            }}
          />
        </LocalizationProvider>
        
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            slotProps={{
              textField: {
                fullWidth: isSmallScreen, // Full width on small screens
                sx: {
                  "& .MuiInputBase-input": {
                    fontSize: isSmallScreen ? "0.6rem" : "1rem",
                  },
                },
              },
            }}
          />
        </LocalizationProvider>
        
      </Box>

      <Button
        variant="contained"
        color="primary"
        onClick={handleExportPDF}
        startIcon={<DownloadIcon />}
        sx={{ minWidth: 150 }}
      >
        Export as PDF
      </Button>
    </Box>

    {loading ? (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    ) : (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} ref={(el) => (chartRefs.current[1] = el)}>
            {renderChart(
              1,
              "Designation-wise Unsafe Act and Condition",
              chartData.designationWise ? (
                <Bar
                  data={chartData.designationWise}
                  options={barChartOptions}
                />
              ) : (
                <Typography align="center" color="error">
                  No data available
                </Typography>
              )
            )}
          </Grid>

          <Grid item xs={12} md={6} ref={(el) => (chartRefs.current[2] = el)}>
            {renderChart(
              2,
              "Area-wise Unsafe Act and Condition",
              chartData.areaWise ? (
                <Bar data={chartData.areaWise} options={barChartOptions} />
              ) : (
                <Typography align="center" color="error">
                  No data available
                </Typography>
              )
            )}
          </Grid>
          <Grid item xs={12} md={6} ref={(el) => (chartRefs.current[3] = el)}>
            {renderChart(
              2,
              "Department-wise Unsafe Act and Condition",
              chartData.departmentWise ? (
                <Bar
                  data={chartData.departmentWise}
                  options={barChartOptions}
                />
              ) : (
                <Typography align="center" color="error">
                  No data available
                </Typography>
              )
            )}
          </Grid>
          <Grid item xs={12} md={12} ref={(el) => (chartRefs.current[4] = el)}>
            {renderChart(
              2,
              "Responsible Department-wise Unsafe Act and Condition",
              chartData.responsibleDepartmentWise ? (
                <Bar
                  data={chartData.responsibleDepartmentWise}
                  options={barChartOptions}
                />
              ) : (
                <Typography align="center" color="error">
                  No data available
                </Typography>
              )
            )}
          </Grid>
        </Grid>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>Detailed Report</DialogTitle>
          <DialogContent>
            <DetailedDataTable
              company_name={company_name}
              startDate={formattedStartDate}
              endDate={formattedEndDate}
              dataType={selectedDataType}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )}
  </Box>
  );
};

export default UnsafeActChart;