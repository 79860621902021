import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getDoc } from "firebase/firestore";
import UserRouter from "./Screens/UserRouter";
import AdminRouter from "./Screens/AdminRouter";
import {SpeedInsights} from '@vercel/speed-insights/react';
import CompanyList from "./Screens/CompanyList";
import { getLoginStatusLocally, getUIDLocally } from "./utils/userUtils";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import  theme  from "./theme";
import UserProfile from "./componants/common/UserProfile";
import AuthenticationModule from "./Screens/AuthenticationModule";



const App = () => {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const isLoggedIn = await getLoginStatusLocally();
//        console.log("isLoggedIn", isLoggedIn);
        if (isLoggedIn) {
          const userId = await getUIDLocally();
          console.log("userId", userId);
          const userDocRef = firebase
            .firestore()
            .collection("peoples")
            .doc("users")
            .collection("users")
            .doc(userId);
          const userSnap = await getDoc(userDocRef);

          if (userSnap.exists()) {
            const userData = userSnap.data();
            //const { company_name, email, permissions, role, user_name } = userData;
            setUser(userData);
            // setIsAdmin(role === 'admin');
            setIsAdmin(false);
          } else {
            setUser(true);
            setIsAdmin(true);
          }
        } else {
          setUser(null);
          // setUser(true);
          setIsAdmin(false);
        }
      } catch (err) {
        console.log(err);
        setUser(null);
        setIsAdmin(false);
      }
    };
    checkLoginStatus();
  }, []);


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <Router>
          <Routes>
            <Route path="/" element={ user ? ( isAdmin ? ( <Navigate to="/company-list" replace /> ) : ( <Navigate to="/user/user" replace /> )) : ( <AuthenticationModule/> ) } />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/company-list" element={<CompanyList />} />
            <Route path="/admin/*" element={<AdminRouter />} />
            <Route path="/admin/dashboard/*" element={<AdminRouter />} />
            <Route path="/user/user/*" element={<UserRouter />} />
            <Route path="/user/manager/*" element={
                //  <ManagerRouter />

                <UserRouter />
              }
            />
          </Routes>
        </Router>
      
    <SpeedInsights/>
    </ThemeProvider>
  );
};

export default App
