import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Paper,
  Checkbox,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Tabs,
  Tab,
  Card,
  CardContent,
  Box,
  Container
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ImageUploader from '../../componants/ImageUploader';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';
import { firestore } from '../../firebase/firebaseConfig';
import moment from 'moment';
import StatusCard from '../../componants/StatusCard';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

const StyledError = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const KaizenReport = ({ userId, email, company_name }) => {
  const [kaizenIdentificationDate, setKaizenIdentificationDate] = useState(new Date());
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [caseCount, setCaseCount] = useState(0);

  const [areaName, setAreaName] = useState('');
  const [areaManagerName, setAreaManagerName] = useState('');
  const [kaizenDescription, setKaizenDescription] = useState('');
  const [identifiedBy, setIdentifiedBy] = useState('');
  const [sourceOfIdentification, setSourceOfIdentification] = useState([]);
  const [imageOfKaizenFormat, setImageOfKaizenFormat] = useState(null);
  const [areas, setAreas] = useState([]);
  const [areaManagers, setAreaManagers] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const companyRef = firestore.collection('company_data').doc(company_name);
      // Fetch areas
      const areasSnapshot = await companyRef
        .collection(company_name)
        .doc('for_admin')
        .collection('company_zones')
        .get();
      setAreas(areasSnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name })));

      // Fetch area managers
      const amSnapshot = await companyRef
        .collection(company_name)
        .doc('for_admin')
        .collection('area_managers')
        .get();
      setAreaManagers(amSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

      // Fetch case count
      const companyDoc = await companyRef.get();
      if (companyDoc.exists) {
        setCaseCount(companyDoc.data().case_count || 0);
      }
      // Fetch user reports
      fetchReports();
    };

    fetchData();
  }, [company_name, userId]);

  const fetchReports = async () => {
    const reportsSnapshot = await firestore
      .collection('company_data')
      .doc(company_name)
      .collection(company_name)
      .doc('for_user')
      .collection('kaizen_reports')
      .where('userId', '==', userId)
      .get();

    const reportsData = reportsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setReports(reportsData);
  };

  const formatDate = (timestamp) => {
    if (typeof timestamp === 'string' && timestamp.includes('-')) {
      return timestamp;
    }
    if (timestamp && timestamp.seconds) {
      return moment(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).format('MMMM D, YYYY');
    }
    return 'Invalid Date';
  };

  const validate = () => {
    const errors = {};
    if (!kaizenIdentificationDate) errors.date = 'Date is required';
    if (!areaName) errors.areaName = '***Area Name is required';
    if (!areaManagerName) errors.areaManagerName = '***Area Manager Name is required';
    if (!kaizenDescription) errors.kaizenDescription = '***KAIZEN Description is required';
    if (!identifiedBy) errors.identifiedBy = '***Identified By (Person\'s Name) is required';
    if (sourceOfIdentification.length === 0) errors.sourceOfIdentification = '***Source of Identification is required';
    if (!imageOfKaizenFormat) errors.imageOfKaizenFormat = '***Image of Kaizen Format is required';
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const uploadImage = async (file) => {
    if (!file) return null;

    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(`kaizen_report_images/${Date.now()}_${file.name}`);

    try {
      await fileRef.put(file);
      const downloadURL = await fileRef.getDownloadURL();
      return downloadURL;
    } catch (error) {
      console.error('Error uploading image: ', error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setIsLoading(true);
      try {
        const caseNumber = caseCount + 1;
        let imageUrl = null;
        if (imageOfKaizenFormat) {
          imageUrl = await uploadImage(imageOfKaizenFormat);
        }

        const docRef = await firebase.firestore()
          .collection('company_data')
          .doc(company_name)
          .collection(company_name)
          .doc('for_user')
          .collection('kaizen_reports')
          .add({
            kaizenIdentificationDate,
            areaName,
            areaManagerName,
            kaizenDescription,
            identifiedBy,
            sourceOfIdentification,
            imageOfKaizenFormat: imageUrl,
            caseNumber,
            userId,
            isPending: true,
          });

        await firestore.collection('company_data').doc(company_name).update({
          case_count: caseNumber,
        });

        const detailRef = firestore
          .collection('company_data')
          .doc(company_name)
          .collection(company_name)
          .doc('for_user')
          .collection('kaizen_reports')
          .doc('details');

        const detailRefDoc = await detailRef.get();

        if (detailRefDoc.exists) {
          await detailRef.delete();
        }
        console.log('Document written with ID: ', docRef.id);

        clearForm();
        setIsLoading(false);
        alert('Report submitted successfully');
      } catch (error) {
        console.error('Error submitting report:', error);
        setIsLoading(false);
        alert('Failed to submit report');
      }
    }
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    setSourceOfIdentification((prevValues) =>
      prevValues.includes(value)
        ? prevValues.filter((item) => item !== value)
        : [...prevValues, value]
    );
  };

  const sourceOfIdentificationOptions = [
    { label: 'HIRA', value: 'HIRA' },
    { label: 'Accident Investigation', value: 'Accident Investigation' },
    { label: 'Gemba Walk', value: 'Gemba Walk' },
    { label: 'General Observation', value: 'General Observation' },
    { label: 'Cross Department', value: 'Cross Department' },
  ];

  const clearForm = () => {
    setKaizenIdentificationDate(new Date());
    setAreaName('');
    setAreaManagerName('');
    setKaizenDescription('');
    setIdentifiedBy('');
    setSourceOfIdentification([]);
    setImageOfKaizenFormat(null);
    setErrors({});
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container maxWidth="lg" sx={{mt:4}}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Date"
                  value={kaizenIdentificationDate}
                  onChange={setKaizenIdentificationDate}
                  renderInput={(params) => <TextField {...params} fullWidth error={!!errors.date} helperText={errors.date} />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.areaName}>
                  <InputLabel>Area Name</InputLabel>
                  <Select
                    value={areaName}
                    onChange={(e) => setAreaName(e.target.value)}
                    label="Area Name"
                  >
                    {areas.map((dept) => (
                      <MenuItem key={dept.id} value={dept.name}>
                        {dept.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.areaName && (
                    <StyledError variant="caption">
                      {errors.areaName}
                    </StyledError>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.areaManagerName}>
                  <InputLabel>Area Manager Name</InputLabel>
                  <Select
                    value={areaManagerName}
                    onChange={(e) => setAreaManagerName(e.target.value)}
                    label="Area Manager Name"
                  >
                    {areaManagers.map((manager) => (
                      <MenuItem key={manager.id} value={manager.name}>
                        {manager.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.areaManagerName && (
                    <StyledError variant="caption">
                      {errors.areaManagerName}
                    </StyledError>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Kaizen Description*"
                  value={kaizenDescription}
                  onChange={(e) => setKaizenDescription(e.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                  error={!!errors.kaizenDescription}
                  helperText={errors.kaizenDescription}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Identified By (Person's Name)*"
                  value={identifiedBy}
                  onChange={(e) => setIdentifiedBy(e.target.value)}
                  fullWidth
                  error={!!errors.identifiedBy}
                  helperText={errors.identifiedBy}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset" error={!!errors.sourceOfIdentification}>
                  <Typography variant="subtitle1"> Source of Identification* </Typography>
                  <FormGroup>
                    {sourceOfIdentificationOptions.map((option) => (
                      <FormControlLabel
                        key={option.value}
                        control={
                          <Checkbox
                            checked={sourceOfIdentification.includes(option.value)}
                            onChange={handleCheckboxChange}
                            value={option.value}
                          />
                        }
                        label={option.label}
                      />
                    ))}
                  </FormGroup>
                  {errors.sourceOfIdentification && (
                    <StyledError>
                      {errors.sourceOfIdentification}
                    </StyledError>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <ImageUploader
                  value={imageOfKaizenFormat}
                  onChange={setImageOfKaizenFormat}
                  error={!!errors.imageOfKaizenFormat}
                  helperText={errors.imageOfKaizenFormat}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 3 }}>
              <StyledButton
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : 'Submit'}
              </StyledButton>
              <Button variant="outlined" onClick={clearForm}>
                Clear
              </Button>
            </Box>
          </form>
        <Container maxWidth="lg" sx={{mt:4}}>  
          <Typography variant="h5" gutterBottom>
            Your Reports
          </Typography>
          <Tabs 
          value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth" 
          
          >
            <Tab label="Pending" />
            <Tab label="Completed" />
          </Tabs>
          <Box mt={3} sx={{paddingBottom:4}}>
          <Grid container spacing={3}>
          {reports
              .filter(report => tabValue === 0 ? report.isPending : !report.isPending)
              .map(report => (
                             
                <StatusCard
                key={report.id}
                status={report.isPending ?'Pending' : 'Completed'}
                severity={report.severity}
                caseNumber={report.caseNumber}
                date={formatDate(report.date)}
                areaName={report.areaName}
                description={report.description}
                />
              ))}
          </Grid>
          </Box>
        </Container>
      </Container>
    </LocalizationProvider>
  );
};

export default KaizenReport;